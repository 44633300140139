import { useState, useMemo, useEffect, useRef, ReactNode, useCallback } from 'react';

import Stack, { StackProps } from '@mui/material/Stack';
import Box from '@mui/material/Box';

// Styles
const wrapper = { overflow: 'hidden' };
const itemsWrapper = {
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': { display: 'none' },
};
const itemWrapper = { scrollSnapAlign: 'center', scrollSnapStop: 'always' };
const indicator = {
  height: '2px',
  width: '8px',
  cursor: 'pointer',
  backgroundColor: theme => theme.palette.grey[100],
  overflow: 'hidden',
};
const indicatorCurrent = {
  height: '2px',
  width: '16px',
  backgroundColor: theme => theme.palette.primary.main,
  overflow: 'hidden',
};

type MapleCarouselProps = {
  height: number;
  items: ReactNode[];
  name: string;
  scrolToItem?: number;
  itemsSpacing?: StackProps['spacing'];
  margins?: number;
  navigation?: 'top' | 'bottom';
};

const Navigation = ({ items, currentItemIndex, handleNavigationClick }) => (
  <Stack direction='row' justifyContent='center' spacing={0.5}>
    {items.map((_, index) => (
      <Box
        key={`${name}-indicator-${index}`}
        sx={index === currentItemIndex ? indicatorCurrent : indicator}
        onClick={() => handleNavigationClick(index)}
      />
    ))}
  </Stack>
);

const MapleCarousel = ({
  height,
  items,
  name,
  scrolToItem,
  itemsSpacing = 1,
  margins = 2,
  navigation,
}: MapleCarouselProps) => {
  const [scrollProps, setScrollProps] = useState({ scrollLeft: 0, width: 0 });
  const ref = useRef<HTMLDivElement>(null);

  const handleNavigationClick = useCallback(
    (scrolToItem: number) => {
      if (ref.current && scrolToItem !== undefined) {
        const { scrollWidth } = ref.current;
        const scrollRanges = scrollWidth / items.length;
        const newScrollPosition = scrollRanges * scrolToItem;
        ref.current.scrollTo({
          left: newScrollPosition,
          behavior: 'smooth',
        });
      }
    },
    [ref, items.length],
  );

  const currentItemIndex = useMemo(
    () => (scrollProps.scrollLeft === 0 ? 0 : Math.ceil(scrollProps.scrollLeft / scrollProps.width)),
    [scrollProps],
  );

  useEffect(() => {
    if (scrolToItem !== undefined) {
      handleNavigationClick(scrolToItem);
    }
  }, [handleNavigationClick, scrolToItem]);

  if (!items?.length || items.every(item => !item)) {
    return null;
  }

  return (
    <Stack spacing={1}>
      {navigation === 'top' && items.length > 1 && (
        <Navigation items={items} currentItemIndex={currentItemIndex} handleNavigationClick={handleNavigationClick} />
      )}
      <Box sx={{ ...wrapper, height }}>
        <Stack
          ref={ref}
          direction='row'
          spacing={itemsSpacing}
          sx={items.length > 1 ? itemsWrapper : { ...itemsWrapper, overflowX: 'hidden' }}
          onScroll={({ currentTarget }) =>
            setScrollProps({ scrollLeft: currentTarget.scrollLeft, width: currentTarget.clientWidth })
          }
        >
          {items.map((item, index) => (
            <Box
              key={`${name}-${index}`}
              sx={{
                ...itemWrapper,
                height: `${height}px`,
                pl: index === 0 ? margins : 0,
                pr: index === items.length - 1 ? margins : 0,
              }}
            >
              {item}
            </Box>
          ))}
        </Stack>
      </Box>
      {navigation === 'bottom' && items.length > 1 && (
        <Navigation items={items} currentItemIndex={currentItemIndex} handleNavigationClick={handleNavigationClick} />
      )}
    </Stack>
  );
};

export default MapleCarousel;
