import { useContext } from 'react';

import MapleButton, { MapleButtonProps } from 'Components/ds/MapleButton';
import MapleIcon from 'Components/ds/MapleIcon';

// Context
import { CommitContext } from 'Context/Commit/Commit';

// Types
import { FormattedCommitment } from 'Context/Portfolio/utils';

export interface RecommitCTAProps extends MapleButtonProps {
  withIcon?: boolean;
  commitment?: FormattedCommitment;
  onClick?: () => void;
}

const iconColor = {
  primary: 'static.white',
  tertiary: 'static.primary',
};

const RecommitCTA = ({ withIcon = false, commitment, onClick, ...props }: RecommitCTAProps) => {
  const { openRecommitModal } = useContext(CommitContext);

  const handleClick = () => {
    openRecommitModal(commitment);
    onClick?.();
  };

  return (
    <>
      <MapleButton
        {...props}
        size={props.size || 'small'}
        variant='contained'
        onClick={handleClick}
        startIcon={withIcon ? <MapleIcon icon='timeLine' color={iconColor[props.color || 'primary']} /> : undefined}
      >
        Recommit
      </MapleButton>
    </>
  );
};

export default RecommitCTA;
