import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// Components
import MapleButton from 'Components/ds/MapleButton';
import MapleTypography from 'Components/ds/MapleTypography';
import ModalGradientCTA from 'Components/ModalGradientCTA';
import MapleIcon from 'Components/ds/MapleIcon';
import MapleIconButton from 'Components/ds/MapleIconButton';

import { ReactComponent as IconSuccess } from 'Assets/icon-modal-success.svg';
import { ReactComponent as IconWithdrawal } from 'Assets/icon-modal-withdrawal.svg';

// Constants
import { etherscanUrl } from 'Constants/network';

// Types
import { PoolAssets } from 'Context/Portfolio/Portfolio';

const amountBlockStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};

const amountStyles = {
  height: '28px',
  color: theme => theme.palette.text.strong,
};

interface CommonSuccessProps {
  heading: string | string[];
  amount?: string;
  asset?: PoolAssets | 'syrup';
  copy?: string | string[];
  transactionHash?: string;
  icon?: 'withdrawal';
  onClose?: () => void;
}

interface CommonFooterProps {
  ctaText: string;
  onCTAClick: () => void;
}

interface CTAFooterProps extends CommonFooterProps, LabeledValue {}

interface SuccessWithFooterButtons extends CommonSuccessProps {
  type: 'withFooterButtons';
  footer: CommonFooterProps;
}

interface SuccessWithFooterCTA extends CommonSuccessProps {
  type: 'withFooterCTA';
  footer: CTAFooterProps;
}

export type StepSuccessProps = SuccessWithFooterButtons | SuccessWithFooterCTA;

function isSuccessWithFooterButtons(props: StepSuccessProps): props is SuccessWithFooterButtons {
  return props.type === 'withFooterButtons';
}

function isSuccessWithFooterCTA(props: StepSuccessProps): props is SuccessWithFooterCTA {
  return props.type === 'withFooterCTA';
}

const StepSuccess = (props: StepSuccessProps) => {
  const { heading, copy, transactionHash, type = 'withFooterButtons', footer, amount, asset, icon, onClose } = props;

  const etherscanBtn = useMemo(() => {
    if (transactionHash) {
      return (
        <MapleButton
          variant={type === 'withFooterButtons' ? 'contained' : 'textLink'}
          color='tertiary'
          fullWidth
          onClick={() => window.open(`${etherscanUrl}/tx/${transactionHash}`, '_blank')}
          endIcon={<MapleIcon icon='arrowRightUp' />}
        >
          View Etherscan
        </MapleButton>
      );
    }

    return null;
  }, [transactionHash, type]);

  const iconEl = useMemo(() => {
    if (icon === 'withdrawal') {
      return <IconWithdrawal />;
    }

    return <IconSuccess />;
  }, [icon]);

  const footerEl = useMemo(() => {
    if (isSuccessWithFooterButtons(props)) {
      return (
        <>
          <MapleButton variant='contained' color='primary' fullWidth onClick={footer.onCTAClick}>
            {footer.ctaText}
          </MapleButton>
          {etherscanBtn}
        </>
      );
    }

    if (isSuccessWithFooterCTA(props)) {
      const { label, value, ctaText, onCTAClick } = props.footer;
      return (
        <>
          {etherscanBtn}
          <ModalGradientCTA type='normal' label={label} value={value} ctaText={ctaText} handleClick={onCTAClick} />
        </>
      );
    }
  }, [etherscanBtn, footer.ctaText, footer.onCTAClick, props]);

  return (
    <Stack direction='column' spacing={2} justifyContent='space-between'>
      <Stack direction='column' alignItems='flex-end'>
        {onClose && (
          <MapleIconButton size='small' color='tertiary' onClick={onClose}>
            <MapleIcon icon='closeLine' />
          </MapleIconButton>
        )}
      </Stack>
      <Stack direction='column' alignItems='center'>
        {iconEl}
      </Stack>
      <Stack spacing={2} pb={2} paddingX={2} alignItems='center'>
        {Array.isArray(heading) ? (
          <MapleTypography variant='h5' color='text.strong' textAlign='center'>
            {heading.map((text, index) => (
              <Box key={index}>{text}</Box>
            ))}
          </MapleTypography>
        ) : (
          <MapleTypography variant='h5' color='text.strong' textAlign='center'>
            {heading}
          </MapleTypography>
        )}

        <Stack sx={amountBlockStyles} gap={0.5}>
          {asset && <MapleIcon icon={asset} height={24} width={24} />}

          <MapleTypography variant='h5' sx={amountStyles}>
            {amount}
          </MapleTypography>
        </Stack>
        {copy ? (
          <Box paddingX={4}>
            {Array.isArray(copy) ? (
              copy.map((text, index) => (
                <MapleTypography key={index} variant='label' size='small' color='text.soft'>
                  {text}
                </MapleTypography>
              ))
            ) : (
              <MapleTypography variant='label' size='small' color='text.soft'>
                {copy}
              </MapleTypography>
            )}
          </Box>
        ) : null}
      </Stack>
      <Stack direction='column' spacing={2} justifyContent='space-between'>
        {footerEl}
      </Stack>
    </Stack>
  );
};

export default StepSuccess;
