import { forwardRef } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

// Interfaces
import { ValueInterface } from 'Utils/valueInterface';

interface CustomProps {
  onChange: (value: string) => void;
  'data-decimals': number;
  'data-value': ValueInterface;
  name: string;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  const handleOnValueChange = values => onChange(values.value);

  return (
    <NumericFormat
      {...other}
      value={props['data-value']?.formatted}
      getInputRef={ref}
      onValueChange={handleOnValueChange}
      thousandSeparator
      valueIsNumericString
      allowNegative={false}
      decimalScale={props['data-decimals']}
    />
  );
});

export default NumericFormatCustom;
