import Stack from '@mui/material/Stack';

// Components
import MapleButton from 'Components/ds/MapleButton';
import PositionRadioGroup from 'Components/PositionRadioGroup';

// Types
import { PositionData, SyrupPositions } from 'Context/Portfolio/Portfolio';
import { CommitFormValues } from 'Context/Commit/Commit';

// Constants
import { ZERO } from 'Constants/numbers';
import { defaultPositionData } from 'Utils/defaultValues';

interface StepCommitSelectProps {
  formValues: CommitFormValues;
  selectPosition: (id: string) => void;
  positions: SyrupPositions;
  goToNextStep: () => void;
}

const StepCommitSelect = ({ formValues, selectPosition, positions, goToNextStep }: StepCommitSelectProps) => {
  const isSelected = Object.keys(formValues.position).some(
    key => formValues.position[key] !== defaultPositionData[key],
  );

  return (
    <Stack direction='column' spacing={3}>
      <PositionRadioGroup
        options={[
          { title: `$${positions.usdc.availableBalance.formatted}`, asset: 'usdc', type: 'basic', id: 'usdc' },
          { title: `$${positions.usdt.availableBalance.formatted}`, asset: 'usdt', type: 'basic', id: 'usdt' },
        ]}
        selectedValue={isSelected ? formValues.position.asset : ''}
        onChange={selectPosition}
      />

      <MapleButton variant='contained' onClick={goToNextStep}>
        Continue
      </MapleButton>
    </Stack>
  );
};

export default StepCommitSelect;
