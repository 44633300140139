import { useContext } from 'react';
import { CommitContext } from 'Context/Commit/Commit';
import CommitModal from 'Pages/Portfolio/CommitModal';
import RecommitModal from 'Pages/Portfolio/RecommitModal';

const CommitFlowModal = () => {
  const { formValues, isModalOpen } = useContext(CommitContext);

  if (!isModalOpen) return null;

  return formValues.type === 'commit' ? <CommitModal /> : <RecommitModal />;
};

export default CommitFlowModal;
