import { useContext, useMemo } from 'react';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Components
import StepCommitInput from 'Components/Steps/StepCommitInput';
import MapleIcon from 'Components/ds/MapleIcon';
import MapleTypography from 'Components/ds/MapleTypography';
import TransactionModal, { TransactionModalProps } from 'Components/TransactionModal';
import StepCommitSelect from 'Components/Steps/StepCommitSelect';

// Context
import { ClientContext } from 'Context/Client';
import { PortfolioContext } from 'Context/Portfolio';
import { DataContext } from 'Context/Data';
import { CommitContext } from 'Context/Commit/Commit';

// Graphql
import { useCommitBalanceMutation } from 'Graphql/schema';

// Utils
import { convertToCommitDays } from 'Utils/points';
import { getWithdrawalShares } from 'Utils/pool';
import { getSimplifiedConnectorName } from 'Utils/transactions/lend';

const CommitModal = () => {
  const navigate = useNavigate();
  const { account, connector, web3Client } = useContext(ClientContext);
  const { positions, pools, refetch } = useContext(PortfolioContext);
  const { lendingAssets } = useContext(DataContext);
  const {
    isDoingTx,
    setIsDoingTx,
    formValues,
    setFormValues,
    step,
    setStep,
    reinitialize,
    isModalOpen,
    selectPosition,
    expectedDrips,
    expectedAPY,
    maturityDate,
  } = useContext(CommitContext);

  const [commitBalance] = useCommitBalanceMutation();

  const handleResetFlow = async () => {
    navigate('/portfolio');
    reinitialize();
  };

  const header = useMemo(() => {
    if (isDoingTx) return undefined;

    if (step === 'selectPosition') {
      return (
        <Stack direction='row' spacing={1} alignItems='center' justifyContent='flex-start' width='100%'>
          <MapleIcon icon='coinsFill' />
          <MapleTypography variant='h6' sx={{ transform: 'translateY(3px)' }}>
            Choose balance to commit
          </MapleTypography>
        </Stack>
      );
    }

    if (step === 'form') {
      return (
        <Stack direction='row' spacing={1} alignItems='center' justifyContent='flex-start' width='100%'>
          <MapleIcon icon={formValues.position.asset} size='32px' />
          <MapleTypography variant='h6' sx={{ transform: 'translateY(3px)' }}>
            Commit
          </MapleTypography>
        </Stack>
      );
    }
  }, [step, isDoingTx, formValues.position]);

  const inProgressProps = useMemo((): TransactionModalProps['inProgressProps'] => {
    return {
      commit: {
        heading: 'Committing',
        copy: 'Follow the prompts in your wallet to commit your balance',
      },
    };
  }, []);

  const successProps = useMemo((): TransactionModalProps['successProps'] => {
    return {
      heading: ['Successfully Committed', `for ${formValues.commitLength} Months`],
      type: 'withFooterButtons',
      footer: {
        ctaText: 'Go to my Portfolio',
        onCTAClick: handleResetFlow,
      },
      amount: formValues.amount.formatted,
      asset: formValues.position.asset,
      onClose: handleResetFlow,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.commitLength, formValues.amount, formValues.position.asset]);

  const failProps = useMemo((): TransactionModalProps['failProps'] => {
    return {
      handleClick: handleResetFlow,
    };
  }, []);

  const handleSelect = () => {
    setStep('form');
  };

  const handleForm = async () => {
    if (!web3Client || !account) return;

    try {
      setStep('commit');
      setIsDoingTx(true);

      const { position, commitLength } = formValues;
      const selectedPool = pools.find(pool => pool.asset.symbol.toLowerCase() === position.asset.toLowerCase());
      if (!selectedPool) throw new Error('Pool not found');

      const shares = getWithdrawalShares({
        amount: formValues.amount.bigNumber,
        pool: selectedPool,
        availableBalance: position.availableBalance.bigNumber,
        availableShares: position.availableShares.bigNumber,
      });

      const message = `Commit ${
        formValues.amount.formatted
      } ${formValues.position.asset.toUpperCase()} for ${commitLength} months`;

      const signature = await web3Client.signMessage({ account, message });

      const { data } = await commitBalance({
        variables: {
          address: account,
          connector: getSimplifiedConnectorName(connector),
          shares: shares.toString(),
          poolId: selectedPool.id,
          days: convertToCommitDays(commitLength),
        },
        context: {
          headers: {
            'x-auth-message': message,
            'x-auth-signature': `${signature}:${account}`,
          },
        },
      });

      if (data?.commitBalance?.success) setStep('success');
      else throw new Error('Failed to commit balance');
    } catch (error) {
      console.error('👻 CommitModal:handleForm :::', { error });
      setStep('fail');
    } finally {
      setIsDoingTx(false);
      refetch();
    }
  };

  return (
    <TransactionModal
      step={step}
      closeModal={handleResetFlow}
      isModalOpen={isModalOpen}
      performingTx={isDoingTx}
      inProgressProps={inProgressProps}
      successProps={successProps}
      failProps={failProps}
      size='small'
      header={header}
      headerAlign='left'
    >
      {step === 'selectPosition' && (
        <StepCommitSelect
          formValues={formValues}
          selectPosition={selectPosition}
          positions={positions}
          goToNextStep={handleSelect}
        />
      )}
      {step === 'form' && (
        <StepCommitInput
          key={formValues.type}
          showAmount={true}
          formValues={formValues}
          setFormValues={setFormValues}
          assets={lendingAssets}
          positions={positions}
          dripsEstimate={expectedDrips}
          yieldEstimate={expectedAPY}
          maturityDate={maturityDate}
          onSubmit={handleForm}
          ctaCopy='Commit'
        />
      )}
    </TransactionModal>
  );
};

export default CommitModal;
