import { SyrupPaletteColor } from '@mui/material/styles';
import { SyrupSemanticPalette, SyrupStatic, Gradient } from 'Theme/types';

import { BLUE, GREEN, NEUTRAL, ORANGE, RED, YELLOW, GRADIENT, PURPLE } from 'Theme/colors';

interface SyrupPalette {
  // Semantic Palettes: Text & Surfaces
  text: SyrupSemanticPalette;
  background: SyrupSemanticPalette;
  icon: SyrupSemanticPalette;
  stroke: SyrupSemanticPalette;

  // Shade Palettes: Colors
  primary: SyrupPaletteColor;
  tertiary: SyrupPaletteColor; // Strictly for MapleButton color="tertiary"
  secondary: SyrupPaletteColor;
  error: SyrupPaletteColor;
  success: SyrupPaletteColor;
  warning: SyrupPaletteColor;
  info: SyrupPaletteColor;

  gradient: Gradient;

  // 🚨 ------------------------------------ 🚨
  // --- How to map Figma file design names to material ui PaletteColor type
  // 🚨 ------------------------------------ 🚨
  // - figma value  => PaletteColor value
  // - dark         => dark
  // - main         => base
  // - light        => light
  // - contrastText => lighter

  // Static Palette: Black & White
  static: SyrupStatic;
}

const palette: Record<'light' | 'dark', SyrupPalette> = {
  light: {
    // Light Mode Shade Palettes: Colors
    // ---------------------------------------------
    primary: {
      dark: ORANGE[900],
      main: ORANGE[500],
      text: ORANGE[300],
      stroke: ORANGE[400],
      light: ORANGE[200],
      contrastText: ORANGE[50],
    },
    secondary: {
      dark: PURPLE[500],
      main: PURPLE[500],
      stroke: PURPLE[400],
      light: PURPLE[500],
      contrastText: PURPLE[500],
    },
    tertiary: {
      // Strictly for MapleButton color="tertiary"
      main: NEUTRAL[50],
      light: NEUTRAL[200],
      stroke: NEUTRAL[400],
      dark: NEUTRAL[300],
      contrastText: NEUTRAL[950],
    },
    error: {
      dark: RED[900],
      main: RED[500],
      stroke: RED[400],
      light: RED[200],
      contrastText: RED[50],
    },
    success: {
      dark: GREEN[900],
      main: GREEN[500],
      stroke: GREEN[400],
      light: GREEN[200],
      contrastText: GREEN[50],
    },
    warning: {
      dark: YELLOW[900],
      main: YELLOW[500],
      stroke: YELLOW[400],
      light: YELLOW[200],
      contrastText: YELLOW[50],
    },
    info: {
      dark: BLUE[900],
      main: BLUE[500],
      stroke: BLUE[400],
      light: BLUE[200],
      contrastText: BLUE[50],
    },
    gradient: GRADIENT,

    // Light Mode Semantic Palettes: Text & Surfaces
    // ---------------------------------------------
    text: {
      white: NEUTRAL[0],
      disabled: NEUTRAL[300],
      soft: NEUTRAL[400],
      sub: NEUTRAL[600],
      strong: NEUTRAL[950],
    },
    background: {
      white: NEUTRAL[0],
      weak: NEUTRAL[50],
      fade: NEUTRAL[100],
      soft: NEUTRAL[200],
      sub: NEUTRAL[300],
      surface: NEUTRAL[800],
      strong: NEUTRAL[950],
      primary: ORANGE[400],
    },
    icon: {
      white: NEUTRAL[0],
      disabled: NEUTRAL[300],
      soft: NEUTRAL[400],
      sub: NEUTRAL[600],
      strong: NEUTRAL[950],
    },
    stroke: {
      white: NEUTRAL[0],
      soft: NEUTRAL[200],
      sub: NEUTRAL[300],
      strong: NEUTRAL[950],
    },

    // Light Mode Static Palette: Black & White
    static: {
      black: NEUTRAL[950],
      white: NEUTRAL[0],
    },
  },
  dark: {
    // Dark Mode Shade Palettes: Colors
    // ---------------------------------------------
    primary: {
      dark: ORANGE[400],
      main: ORANGE[500],
      stroke: ORANGE[400],
      light: ORANGE[200],
      contrastText: ORANGE[50],
    },
    secondary: {
      dark: PURPLE[500],
      main: PURPLE[500],
      stroke: PURPLE[400],
      light: PURPLE[500],
      contrastText: PURPLE[500],
    },
    tertiary: {
      // Strictly for MapleButton color="tertiary"
      main: NEUTRAL[50],
      light: NEUTRAL[200],
      stroke: NEUTRAL[400],
      dark: NEUTRAL[300],
      contrastText: NEUTRAL[950],
    },
    error: {
      dark: RED[400],
      main: RED[600],
      stroke: RED[400],
      light: RED[200],
      contrastText: RED[50],
    },
    success: {
      dark: GREEN[400],
      main: GREEN[600],
      stroke: GREEN[400],
      light: GREEN[200],
      contrastText: GREEN[50],
    },
    warning: {
      dark: YELLOW[400],
      main: YELLOW[600],
      stroke: YELLOW[400],
      light: YELLOW[200],
      contrastText: YELLOW[50],
    },
    info: {
      dark: BLUE[400],
      main: BLUE[600],
      stroke: BLUE[400],
      light: BLUE[200],
      contrastText: BLUE[50],
    },
    gradient: GRADIENT,

    // Dark Mode Semantic Palettes: Text & Surfaces
    // ---------------------------------------------
    text: {
      white: NEUTRAL[950],
      disabled: NEUTRAL[600],
      soft: NEUTRAL[500],
      sub: NEUTRAL[400],
      strong: NEUTRAL[0],
    },
    background: {
      white: NEUTRAL[950],
      weak: NEUTRAL[900],
      fade: NEUTRAL[800],
      soft: NEUTRAL[700],
      sub: NEUTRAL[600],
      surface: NEUTRAL[200],
      strong: NEUTRAL[0],
      primary: ORANGE[400],
    },
    icon: {
      white: NEUTRAL[950],
      disabled: NEUTRAL[600],
      soft: NEUTRAL[500],
      sub: NEUTRAL[400],
      strong: NEUTRAL[0],
    },
    stroke: {
      white: NEUTRAL[950],
      soft: NEUTRAL[700],
      sub: NEUTRAL[600],
      strong: NEUTRAL[0],
    },

    // Dark Mode Static Palette: Black & White
    // ---------------------------------------------
    static: {
      black: NEUTRAL[950],
      white: NEUTRAL[0],
    },
  },
};

export default palette;
