import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';

const StyledSwitch = styled(Switch)<SwitchProps>(({ theme, checked }) => ({
  height: '16px',
  width: '28px',
  '.MuiSwitch-track': {
    backgroundColor: `${checked ? theme.palette.success.main : theme.palette.background.soft} !important`,
    borderRadius: theme.shape.lg,
    opacity: `1 !important`,
    height: '16px',
    width: '28px',
  },
  '.MuiSwitch-switchBase': {
    padding: '2px',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
    },
  },
  '.MuiSwitch-thumb': {
    backgroundColor: `${theme.palette.background.white}`,
    position: 'relative',
    height: '12px',
    width: '12px',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '4px',
      height: '4px',
      borderRadius: '50%',
      backgroundColor: `${checked ? theme.palette.success.main : theme.palette.background.soft} !important`,
    },
  },
}));

export default StyledSwitch;
