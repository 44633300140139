import { ReactNode } from 'react';
import Stack from '@mui/material/Stack';

// Components
import MapleIcon from 'Components/ds/MapleIcon';
import MapleIconButton from 'Components/ds/MapleIconButton';
import MapleTypography from 'Components/ds/MapleTypography';

// Hooks
import useBreakpoint from 'Hooks/useBreakpoint';

// Utils
import { hexToRgba } from 'Utils/styles';

// Styles
const bannerWrapper = {
  minWidth: theme => ({ xs: `calc(100vw - ${theme.spacing(2)})`, md: `calc(216px - ${theme.spacing(1)})` }),
  padding: theme => theme.spacing(0, 1, 1),
  height: '100%',
};
const bannerContent = {
  borderRadius: theme => theme.shape.lg,
  p: { xs: 0, md: 1 },
  height: '100%',
};
const bannerMedia = {
  width: '100%',
  borderRadius: theme => theme.shape.md,
  overflow: 'hidden',
  position: 'relative',
  height: { xs: '160px', md: '100px' },
};
const bannerTag = {
  position: 'absolute',
  top: theme => theme.spacing(1),
  left: theme => theme.spacing(1),
  pt: { xs: 1.5, md: 0.25 },
  pb: { xs: 1, md: 0 },
  px: { xs: 2, md: 2 },
  fontSize: { xs: '17px', md: '12px' },
  borderRadius: theme => theme.shape.xs,
};
const bannerTagOrange = {
  ...bannerTag,
  backgroundColor: theme => theme.palette.primary.main,
  color: theme => theme.palette.text.white,
};
const bannerTagWhite = {
  ...bannerTag,
  backgroundColor: theme => hexToRgba(theme.palette.background.white, 0.5),
  color: theme => theme.palette.text.string,
};
const bannerCloseBtn = {
  position: 'absolute',
  top: theme => theme.spacing(1),
  right: theme => theme.spacing(1),
};
const bannerText = {
  px: { xs: 2, md: 0 },
};

type BannerProps = {
  media: ReactNode;
  tag?: {
    label: string;
    color: 'orange' | 'white';
  };
  handleClose: () => void;
  title: string;
  description: string;
  footer: ReactNode;
  backgroundColor?: string;
};

const Banner = ({ media, tag, handleClose, title, description, footer, backgroundColor }: BannerProps) => {
  const { isDesktopDevice } = useBreakpoint();

  return (
    <Stack sx={bannerWrapper}>
      <Stack
        sx={{
          ...bannerContent,
          backgroundColor: theme => ({ xs: 'none', md: backgroundColor || theme.palette.background.weak }),
        }}
        justifyContent='space-between'
        spacing={1.5}
      >
        <Stack spacing={{ xs: 4.5, md: 1.5 }}>
          <Stack alignItems='center' justifyContent='center' sx={bannerMedia}>
            {media}
            {tag && (
              <MapleTypography variant='h7' sx={tag.color === 'orange' ? bannerTagOrange : bannerTagWhite}>
                {tag.label}
              </MapleTypography>
            )}
            <MapleIconButton size='xSmall' color='tertiary' onClick={handleClose} sx={bannerCloseBtn}>
              <MapleIcon icon='closeLine' />
            </MapleIconButton>
          </Stack>
          <Stack spacing={0.5} alignItems='center' sx={bannerText}>
            <MapleTypography
              {...(isDesktopDevice ? { variant: 'label', size: 'small' } : { variant: 'h6' })}
              textAlign='center'
            >
              {title}
            </MapleTypography>
            <MapleTypography variant='label' size={isDesktopDevice ? '2xSmall' : 'small'} textAlign='center'>
              {description}
            </MapleTypography>
          </Stack>
        </Stack>
        {footer}
      </Stack>
    </Stack>
  );
};

export default Banner;
