import {
  createContext,
  ReactNode,
  ReactElement,
  useState,
  useEffect,
  useContext,
  SetStateAction,
  Dispatch,
  useMemo,
  useCallback,
} from 'react';

// Context
import { ClientContext } from 'Context/Client';
import { DataContext } from 'Context/Data/Data';

// Constants / Types
import { buildPercentageValueInterfaceFromBigNumber, ValueInterface } from 'Utils/valueInterface';
import {
  baseTransactionControls,
  defaultResetControls,
  ResetControls,
  TransactionConfig,
  TransactionControls,
} from 'Utils/transactionConfig';
import { FormattedCommitment } from 'Context/Portfolio/utils';
import { APY_DECIMALS } from 'Constants/index';

// Utils
import { getSyrupApy } from 'Utils/pool';

import { defaultValueInterface } from 'Utils/valueInterface';
import { PortfolioContext, PositionData } from 'Context/Portfolio/Portfolio';
import { defaultCommitFormValues } from 'Utils/defaultValues';
import { commitmentLengthMapShort } from 'Utils/points';
import { applyCustomDateFormat, getNowTimestamp, removeTimeComponent } from 'Utils/timeAndDates';
import { getCommitmentEnds, getPointsEstimate } from 'Utils/points';

type OutcomeSteps = 'success' | 'fail';
type CommitSteps = 'selectPosition' | 'form' | 'commit' | OutcomeSteps;
type RecommitSteps = 'selectCommitment' | 'form' | 'recommit' | OutcomeSteps;

export type CommitModalSteps = CommitSteps | RecommitSteps;
type CommitTxType = 'commit' | 'recommit';

export type CommitmentLength = '3' | '6';

export interface CommitFormValues {
  amount: ValueInterface;
  commitLength: CommitmentLength;
  position: PositionData;
  commitment: FormattedCommitment;
  type: CommitTxType;
}

const defaultTransactionConfig: TransactionConfig<CommitModalSteps, CommitTxType> = {
  flowType: 'normal',
  txType: 'commit',
  steps: ['form'],
  setSteps: () => {},
};

const defaultTransactionControls: TransactionControls<CommitModalSteps> = {
  ...baseTransactionControls,
  step: 'form',
};

interface EstimatedData {
  expectedDrips: ValueInterface;
  expectedAPY: ValueInterface;
  maturityDate: string;
}

const defaultEstData: EstimatedData = {
  expectedDrips: { ...defaultValueInterface },
  expectedAPY: { ...defaultValueInterface },
  maturityDate: '',
};

type CommitData = TransactionConfig<CommitModalSteps, CommitTxType> &
  TransactionControls<CommitModalSteps> &
  ResetControls &
  EstimatedData & {
    formValues: CommitFormValues;
    setFormValues: Dispatch<SetStateAction<CommitFormValues>>;

    commitments: FormattedCommitment[];
    loading: boolean;
    openRecommitModal: (commitment?: FormattedCommitment) => void;
    selectCommitment: (id: string) => void;
    openCommitModal: (position?: PositionData) => void;
    selectPosition: (id: string) => void;
  };

const defaultCommitData: CommitData = {
  ...defaultTransactionConfig,
  ...defaultTransactionControls,
  ...defaultResetControls,
  ...defaultEstData,
  formValues: { ...defaultCommitFormValues },
  setFormValues: () => {},
  loading: true,
  commitments: [],
  openRecommitModal: () => {},
  selectCommitment: () => {},
  selectPosition: () => {},
  openCommitModal: () => {},
};

export const CommitContext = createContext<CommitData>(defaultCommitData);

export interface CommitProps {
  children: ReactNode;
}

export const CommitProvider = ({ children }: CommitProps): ReactElement => {
  const { account } = useContext(ClientContext);
  const { positions, loading: positionsLoading } = useContext(PortfolioContext);
  const { pools } = useContext(DataContext);

  const [steps, setSteps] = useState<CommitModalSteps[]>(['form']);
  const [step, setStep] = useState<CommitModalSteps>('form');
  const [isDoingTx, setIsDoingTx] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<CommitFormValues>({ ...defaultCommitFormValues });

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!account || positionsLoading) return;

    reinitialize();
  }, [account, positionsLoading]);

  const init = () => {
    setSteps(['form']);
    setStep('form');
  };

  const estimatedData = useMemo(() => {
    const { position, commitLength, amount, commitment, type } = formValues;

    const { asset, value } = (() => {
      switch (type) {
        case 'recommit':
          return {
            asset: commitment.asset,
            value: commitment.amount,
          };
        case 'commit':
        default:
          return {
            asset: position.asset,
            value: amount,
          };
      }
    })();

    const selectedPool = pools.find(pool => pool.asset.symbol.toLowerCase() === asset.toLowerCase());

    const startDate = removeTimeComponent(new Date(getNowTimestamp() * 1000).toISOString()); // DD:MM:YYYY
    const startDateTimestamp = new Date(startDate).getTime(); // DD:MM:YYYY 00:00:00 in milliseconds
    const commitmentDays = +commitLength * 30; // assume 30-day months
    const commitmentEndsTimestamp = getCommitmentEnds(startDateTimestamp, commitmentDays);
    const unlockDate = applyCustomDateFormat(commitmentEndsTimestamp);

    return {
      expectedDrips: getPointsEstimate(value.bigNumber, +commitLength),
      expectedAPY: buildPercentageValueInterfaceFromBigNumber(BigInt(getSyrupApy(selectedPool!)), APY_DECIMALS),

      maturityDate: unlockDate,
    };
  }, [formValues, pools]);

  const commitments = useMemo(() => {
    return Object.values(positions)
      .flatMap(({ commitments }) => commitments)
      .sort((a, b) => a.unlockTimestamp - b.unlockTimestamp);
  }, [positions]);

  const selectCommitment = useCallback(
    (id: string) => {
      const commitment = commitments.find(commitment => commitment.id === id) || commitments[0];
      setFormValues(prevState => ({
        ...prevState,
        commitment,
        commitLength: commitmentLengthMapShort[commitment.length],
        type: 'recommit',
      }));
    },
    [commitments],
  );

  const openRecommitModal = useCallback(
    (commitment?: FormattedCommitment) => {
      let steps: CommitModalSteps[] = ['form'];

      if (commitment) {
        selectCommitment(commitment.id);
      } else if (commitments.length === 1) {
        selectCommitment(commitments[0].id);
      } else {
        steps = ['selectCommitment', 'form'];
        setFormValues(prevState => ({ ...prevState, type: 'recommit' }));
      }

      setSteps(steps);
      setStep(steps[0]);

      setIsModalOpen(true);
    },
    [commitments],
  );

  const selectPosition = useCallback(
    (asset: string) => {
      const position = positions[asset];
      setFormValues(prevState => ({
        ...prevState,
        position,
        type: 'commit',
      }));
    },
    [positions],
  );

  const openCommitModal = useCallback(
    (position?: PositionData) => {
      const uncommittedPositions = Object.values(positions).filter(position => position.availableBalance.bigNumber > 0);

      let steps: CommitModalSteps[] = ['form'];
      if (position) {
        selectPosition(position.asset);
      } else if (uncommittedPositions.length === 1) {
        selectPosition(uncommittedPositions[0].asset);
      } else {
        steps = ['selectPosition', 'form'];
        setFormValues(prevState => ({ ...prevState, type: 'commit' }));
      }

      setSteps(steps);
      setStep(steps[0]);

      setIsModalOpen(true);
    },
    [positions],
  );

  // 🚨 --- Resets
  // ------------------------------------------------------------
  // softReset():
  // - What? -  Resets "Transaction State"
  const resetTxState = () => {
    setStep('form');
    setIsDoingTx(false);
  };

  // hardReset():
  // - What? -  Resets "Transaction Config" and "Transaction State"
  const resetTxConfig = () => {
    setSteps(['form']);
    setIsModalOpen(false);
  };

  const softReset = () => {
    resetTxState();
  };

  const hardReset = async () => {
    resetTxConfig();
    resetTxState();
  };

  const reinitialize = async () => {
    hardReset();
    setFormValues({ ...defaultCommitFormValues });
    init();
  };

  return (
    <CommitContext.Provider
      value={{
        // ⚙️ Transaction Config ⚙️
        flowType: 'normal',
        txType: formValues.type,
        steps,
        setSteps,

        // 🛠️ Transaction State 🛠️
        step,
        setStep,
        isDoingTx,
        setIsDoingTx,
        isModalOpen,
        setIsModalOpen,

        // ↩️ Resets ↩️
        softReset,
        hardReset,
        reinitialize,

        // 📖 Form Values 📖
        formValues,
        setFormValues,

        // Other values
        ...estimatedData,
        commitments,
        loading: isLoading,
        openRecommitModal,
        selectCommitment,
        openCommitModal,
        selectPosition,
      }}
    >
      {children}
    </CommitContext.Provider>
  );
};
