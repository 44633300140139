import { useContext } from 'react';

import MapleButton, { MapleButtonProps } from 'Components/ds/MapleButton';
import MapleIcon from 'Components/ds/MapleIcon';

// Context
import { CommitContext } from 'Context/Commit/Commit';
import { PositionData } from 'Context/Portfolio/Portfolio';

export interface CommitCTAProps extends MapleButtonProps {
  withIcon?: boolean;
  position?: PositionData;
  onClick?: () => void;
}

const iconColor = {
  primary: 'static.white',
  tertiary: 'static.primary',
};

const CommitCTA = ({ withIcon = false, position, onClick, ...props }: CommitCTAProps) => {
  const { openCommitModal } = useContext(CommitContext);

  const handleClick = () => {
    openCommitModal(position);
    onClick?.();
  };

  return (
    <>
      <MapleButton
        {...props}
        size={props.size || 'small'}
        variant='contained'
        onClick={handleClick}
        startIcon={withIcon ? <MapleIcon icon='coinsFill' color={iconColor[props.color || 'primary']} /> : undefined}
      >
        Commit
      </MapleButton>
    </>
  );
};

export default CommitCTA;
