import { Address, Hex, TransactionReceipt, stringToHex } from 'viem';
import { PermitSignature } from 'wagmi-permit';

// Abis
import syrupRouterAbi from 'Contracts/abis/SyrupRouter';

// Constants
import { WETH_DECIMALS } from 'Constants';

// Utils
import { buildValueInterface } from '../valueInterface';

// Types
import { CommonTransactionInputs } from './common';

interface DepositWithPermitInputs extends CommonTransactionInputs {
  depositAmount: bigint;
  depositData: Hex;
  deadline: bigint;
  permit: PermitSignature;
  contractAddress: Address;
}

export const depositWithPermitEstimateGas = async ({
  client,
  depositAmount,
  depositData,
  deadline,
  permit,
  account,
  contractAddress,
}: DepositWithPermitInputs) => {
  const { v, r, s } = permit;

  const gasEstimate = await client.estimateContractGas({
    address: contractAddress,
    abi: syrupRouterAbi,
    functionName: 'depositWithPermit',
    args: [depositAmount, deadline, v, r, s, depositData],
    account,
  });

  return buildValueInterface(gasEstimate.toString(), WETH_DECIMALS);
};

export const depositWithPermit = async ({
  account,
  chain,
  client,
  deadline,
  depositAmount,
  depositData,
  permit,
  contractAddress,
}: DepositWithPermitInputs): Promise<TransactionReceipt> => {
  const { v, r, s } = permit;

  const depositPermitTxHash = await client.writeContract({
    address: contractAddress,
    abi: syrupRouterAbi,
    functionName: 'depositWithPermit',
    args: [depositAmount, deadline, v, r, s, depositData],
    chain,
    account,
  });

  const depositPermitTxReceipt = await client.getTransactionReceipt({
    hash: depositPermitTxHash,
    confirmations: 1,
  });

  return depositPermitTxReceipt;
};

export interface AuthorizeInputs {
  bitmap: bigint;
  authDeadline: bigint;
  authSignature: PermitSignature;
}

type AuthorizeAndDepositWithPermitInputs = DepositWithPermitInputs & AuthorizeInputs;

export const authorizeAndDepositWithPermitEstimateGas = async ({
  client,
  bitmap,
  authDeadline,
  authSignature,
  depositAmount,
  depositData,
  deadline,
  permit,
  account,
  contractAddress,
}: AuthorizeAndDepositWithPermitInputs) => {
  const { v: authV, r: authR, s: authS } = authSignature;
  const { v, r, s } = permit;

  const gasEstimate = await client.estimateContractGas({
    address: contractAddress,
    abi: syrupRouterAbi,
    functionName: 'authorizeAndDepositWithPermit',
    args: [bitmap, authDeadline, authV, authR, authS, depositAmount, depositData, deadline, v, r, s],
    account,
  });

  return buildValueInterface(gasEstimate.toString(), WETH_DECIMALS);
};

export const authorizeAndDepositWithPermit = async ({
  account,
  chain,
  client,
  bitmap,
  authDeadline,
  authSignature,
  depositAmount,
  depositData,
  deadline,
  permit,
  contractAddress,
}: AuthorizeAndDepositWithPermitInputs): Promise<TransactionReceipt> => {
  const { v: authV, r: authR, s: authS } = authSignature;
  const { v, r, s } = permit;
  try {
    const authAndDepositTxHash = await client.writeContract({
      address: contractAddress,
      abi: syrupRouterAbi,
      functionName: 'authorizeAndDepositWithPermit',
      args: [bitmap, authDeadline, authV, authR, authS, depositAmount, depositData, deadline, v, r, s],
      chain,
      account,
    });

    const authAndDepositTxReceipt = await client.getTransactionReceipt({
      hash: authAndDepositTxHash,
    });

    return authAndDepositTxReceipt;
  } catch (innerError: unknown) {
    console.error('Error in authorizeAndDepositWithPermit:', innerError);
    throw innerError; // Re-throw the error to be caught by the outer catch block
  }
};

export interface SyrupDepositInputs extends CommonTransactionInputs {
  depositAmount: bigint;
  depositData: Hex;
  contractAddress: Address;
}

export const syrupDepositEstimateGas = async ({
  client,
  depositAmount,
  depositData,
  account,
  contractAddress,
}: SyrupDepositInputs) => {
  const gasEstimate = await client.estimateContractGas({
    address: contractAddress,
    abi: syrupRouterAbi,
    functionName: 'deposit',
    args: [depositAmount, depositData],
    account,
  });

  return buildValueInterface(gasEstimate.toString(), WETH_DECIMALS);
};

export const syrupDeposit = async ({
  client,
  account,
  chain,
  depositAmount,
  depositData,
  contractAddress,
}: SyrupDepositInputs): Promise<TransactionReceipt> => {
  const depositTxHash = await client.writeContract({
    address: contractAddress,
    abi: syrupRouterAbi,
    functionName: 'deposit',
    args: [depositAmount, depositData],
    chain,
    account,
  });

  const depositTxReceipt = await client.getTransactionReceipt({
    hash: depositTxHash,
  });

  return depositTxReceipt;
};

type AuthorizeAndDepositInputs = SyrupDepositInputs & AuthorizeInputs;

export const authorizeAndDepositEstimateGas = async ({
  client,
  bitmap,
  authDeadline,
  authSignature,
  depositAmount,
  depositData,
  account,
  contractAddress,
}: AuthorizeAndDepositInputs) => {
  const { v, r, s } = authSignature;

  const gasEstimate = await client.estimateContractGas({
    address: contractAddress,
    abi: syrupRouterAbi,
    functionName: 'authorizeAndDeposit',
    args: [bitmap, authDeadline, v, r, s, depositAmount, depositData],
    account,
  });

  return buildValueInterface(gasEstimate.toString(), WETH_DECIMALS);
};

export const authorizeAndDeposit = async ({
  client,
  account,
  chain,
  bitmap,
  authDeadline,
  authSignature,
  depositAmount,
  depositData,
  contractAddress,
}: AuthorizeAndDepositInputs): Promise<TransactionReceipt> => {
  const { v, r, s } = authSignature;

  try {
    const authAndDepositTxHash = await client.writeContract({
      address: contractAddress,
      abi: syrupRouterAbi,
      functionName: 'authorizeAndDeposit',
      args: [bitmap, authDeadline, v, r, s, depositAmount, depositData],
      chain,
      account,
    });

    const authAndDepositTxReceipt = await client.getTransactionReceipt({
      hash: authAndDepositTxHash,
    });

    return authAndDepositTxReceipt;
  } catch (innerError: unknown) {
    console.error('Error in authorizeAndDeposit:', innerError);
    throw innerError; // Re-throw the error to be caught by the outer catch block
  }
};

export const CONNECTOR_KEYWORDS = [
  'coinbase',
  'metamask',
  'exodus',
  'ledger',
  'okex',
  'phantom',
  'walletconnect',
  'safe',
  'injected',
  'binance',
];

// connectorId might be returned as coinbaseWalletSDK, io.metamask etc
// this func will simplify it to "coinbase", "metamask" etc
export const getSimplifiedConnectorName = (connectorId: string): string => {
  const connector = connectorId.toLowerCase();
  for (const connectorString of CONNECTOR_KEYWORDS) {
    if (connector.includes(connectorString)) {
      return connectorString;
    }
  }
  return connector.slice(0, 10);
};

export const buildDepositData = (commitment: string, connectorId: string) => {
  const commitmentStr = `${+commitment * 30}`;
  const connectorIdStr = getSimplifiedConnectorName(connectorId);
  const depositDataStr = `${commitmentStr}:${connectorIdStr}`;

  return stringToHex(depositDataStr, { size: 32 });
};
