import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

// Components
import MapleIconButton from 'Components/ds/MapleIconButton';
import MapleIcon from 'Components/ds/MapleIcon';
import MapleDrawer from 'Components/ds/MapleDrawer';

// Hooks
import useBreakpoint from 'Hooks/useBreakpoint';

type Size = 'small' | 'medium' | 'large';

export interface MapleDialogProps extends DialogProps {
  // Layout props
  children: ReactNode;
  header?: ReactNode;
  image?: ReactNode;
  footer?: ReactNode;
  size?: Size;
  withCloseIcon?: boolean;
  headerAlign?: 'left' | 'default';
  padding?: number;
  // Behavior props
  handleClose?: () => void;
  disableClose?: boolean;
}

const sizeMap: Record<Size, string> = {
  small: '448px',
  medium: '600px',
  large: '800px',
};

const StyledDialog = styled(Dialog)<MapleDialogProps & { size?: Size }>(({ theme, size = 'small' }) => ({
  backgroundColor: 'none',

  '& .MuiDialog-paper': {
    borderRadius: theme.shape.lg,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: sizeMap[size],
    maxWidth: `calc(100vw - ${theme.spacing(4)})`,
    backgroundColor: theme.palette.background.weak,
    border: `1px solid ${theme.palette.stroke.soft}`,
    overflow: 'visible',
    boxShadow: theme.syrupShadows.xl,
  },

  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    backdropFilter: 'blur(18px)',
  },

  '.CloseButton': {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(3),
  },

  '.footer': {
    width: '100%',
    padding: theme.spacing(6, 2, 2, 2),
    position: 'absolute',
    bottom: theme.spacing(-6.5),
    backgroundColor: theme.palette.background.weak,
    boxShadow: theme.syrupShadows.lg,
    border: `1px solid ${theme.palette.stroke.soft}`,
    zIndex: -1,
    borderRadius: theme.shape.lg,
  },
}));

const MapleDialog = ({
  header,
  image,
  children,
  footer,
  handleClose,
  withCloseIcon,
  disableClose,
  padding = 3,
  headerAlign = 'default',
  ...props
}: MapleDialogProps) => {
  const { isDesktopDevice } = useBreakpoint();

  const handleOnClose = () => {
    if (disableClose) return;

    if (handleClose) {
      handleClose();
    }
  };

  const headerDefaultSx = {
    px: withCloseIcon ? 8 : 3,
  };

  if (isDesktopDevice)
    return (
      <StyledDialog onClose={handleOnClose} {...props}>
        <Stack
          spacing={3}
          sx={{
            pt: withCloseIcon && !header && !image ? 9 : padding,
            pb: padding,
            borderRadius: theme => theme.shape.lg,
            backgroundColor: theme => theme.palette.background.white,
          }}
        >
          {header && (
            <Box sx={{ width: '100%', px: headerAlign === 'left' ? padding : headerDefaultSx.px }}>{header}</Box>
          )}
          {image && <Box sx={{ width: '100%', px: padding }}>{image}</Box>}
          {children && <Box sx={{ width: '100%', px: padding }}>{children}</Box>}
        </Stack>
        {footer && <Box className='footer'>{footer}</Box>}
        {withCloseIcon && (
          <MapleIconButton
            size={isDesktopDevice ? 'small' : 'xSmall'}
            color='tertiary'
            onClick={handleClose}
            className='CloseButton'
          >
            <MapleIcon icon='closeLine' />
          </MapleIconButton>
        )}
      </StyledDialog>
    );

  return (
    <MapleDrawer open={props.open} onClose={handleOnClose} anchor='bottom'>
      <Stack
        spacing={3}
        sx={{
          pt: withCloseIcon && !header && !image ? 9.5 : padding,
          pb: footer ? 0 : padding,
          border: theme => `1px solid ${theme.palette.stroke.soft}`,
          backgroundColor: theme => theme.palette.background.white,
        }}
      >
        {header && (
          <Box sx={{ width: '100%', px: headerAlign === 'left' ? padding : headerDefaultSx.px }}>{header}</Box>
        )}
        {image && <Box sx={{ width: '100%', px: padding }}>{image}</Box>}
        {children && <Box sx={{ width: '100%', px: padding }}>{children}</Box>}
        {footer && (
          <Box sx={{ width: '100%', borderTop: theme => `1px solid ${theme.palette.stroke.soft}`, p: 2 }}>{footer}</Box>
        )}
      </Stack>

      {withCloseIcon && (
        <MapleIconButton
          size={isDesktopDevice ? 'small' : 'xSmall'}
          color='tertiary'
          onClick={handleClose}
          className='CloseButton'
        >
          <MapleIcon icon='closeLine' />
        </MapleIconButton>
      )}
    </MapleDrawer>
  );
};

export default MapleDialog;
