import { Addresses } from 'Contracts/addresses';

const addresses: Addresses = {
  mpl: '0x33349b282065b0284d756f0577fb39c158f935e6',
  xMPL: '0x4937a209d4cdbd3ecd48857277cfd4da4d82914c',
  syrupToken: '0x643c4e15d7d62ad0abec4a9bd4b001aa3ef52d66',
  stSyrup: '0xc7e8b36e0766d9b04c93de68a9d47dd11f260b45',
  syrupTokenMigrator: '0x9c9499edd0cd2dcbc3c9dd5070baf54777ad8f2c',
  mplUserActions: '0x75b7b44dcd28df0beda913ae2809ede986e8f461',
  syrupDrip: '0x509712f368255e92410893ba2e488f40f7e986ea',
  pendleMulticall: '0xeefba1e63905ef1d7acba5a8513c70307c1ce441',
  pendleLP: '0x373dc7be84fadebc2e879c98289fc662c6985946',
  pendlePT: '0x57f6bddf2e015feb13e8f86cb7bb5a6a62723ffe',
  pendleYT: '0x261fce0ad922bfda890760eee49042e43bbe2c82',
  pendleLPV2: '0x580e40c15261f7baf18ea50f562118ae99361096',
  pendlePTV2: '0x2beeb2c4809954e5b514a3205afbdc097eb810b4',
  pendleYTV2: '0x01ece02951395b7adba57ca3281c4d6a565d347e',
};

export default addresses;
