import { Radio, RadioProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const iconSize = { fontSize: 20 };

const UnselectedIcon = props => (
  <SvgIcon {...props} sx={iconSize} viewBox='0 0 20 20'>
    <g filter='url(#filter0_i_575_18235)'>
      <path
        d='M2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z'
        fill='#E2E4E9'
      />
    </g>
    <defs>
      <filter
        id='filter0_i_575_18235'
        x='2'
        y='2'
        width='16'
        height='18'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='2' />
        <feGaussianBlur stdDeviation='1' />
        <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
        <feColorMatrix type='matrix' values='0 0 0 0 0.0588235 0 0 0 0 0.0588235 0 0 0 0 0.0627451 0 0 0 0.08 0' />
        <feBlend mode='normal' in2='shape' result='effect1_innerShadow_575_18235' />
      </filter>
    </defs>
  </SvgIcon>
);

const SelectedIcon = props => (
  <SvgIcon {...props} sx={iconSize} viewBox='0 0 20 20'>
    <g filter='url(#filter0_i_575_13632)'>
      <path
        d='M2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z'
        fill='#FC8042'
      />
    </g>
    <g filter='url(#filter1_di_575_13632)'>
      <rect x='6' y='6' width='8' height='8' rx='4' fill='white' />
      <rect x='6.5' y='6.5' width='7' height='7' rx='3.5' stroke='white' />
    </g>
    <defs>
      <filter
        id='filter0_i_575_13632'
        x='2'
        y='2'
        width='16'
        height='18'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='2' />
        <feGaussianBlur stdDeviation='1' />
        <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
        <feColorMatrix type='matrix' values='0 0 0 0 0.0864 0 0 0 0 0.14784 0 0 0 0 0.3936 0 0 0 0.32 0' />
        <feBlend mode='normal' in2='shape' result='effect1_innerShadow_575_13632' />
      </filter>
      <filter
        id='filter1_di_575_13632'
        x='4'
        y='4'
        width='12'
        height='14'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='2' />
        <feGaussianBlur stdDeviation='1' />
        <feColorMatrix type='matrix' values='0 0 0 0 0.105882 0 0 0 0 0.109804 0 0 0 0 0.113725 0 0 0 0.12 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_575_13632' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_575_13632' result='shape' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='-2' />
        <feGaussianBlur stdDeviation='1.5' />
        <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
        <feColorMatrix type='matrix' values='0 0 0 0 0.813571 0 0 0 0 0.82 0 0 0 0 0.826429 0 0 0 1 0' />
        <feBlend mode='normal' in2='shape' result='effect2_innerShadow_575_13632' />
      </filter>
    </defs>
  </SvgIcon>
);

const MapleRadioButton = ({ ...props }: RadioProps) => {
  return (
    <Radio
      {...props}
      icon={<UnselectedIcon />}
      checkedIcon={<SelectedIcon />}
      sx={{
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&.Mui-checked': {
          color: 'transparent',
        },
      }}
    />
  );
};

export default MapleRadioButton;
