import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import Stack, { StackProps } from '@mui/material/Stack';

// Components
import MapleIcon from 'Components/ds/MapleIcon';
import MapleButton from 'Components/ds/MapleButton';

// Types
import { NavItemProps, supportNavItems } from 'Constants/navigation';

const shouldForwardProp = (prop: string) => !['isActive'].includes(prop);

const HorizontalWrapper = styled(Stack, { shouldForwardProp })<StackProps>(({ theme }) => {
  return {
    margin: '0 !important',
    padding: theme.spacing(1, 2),
    borderTop: `1px solid ${theme.palette.stroke.soft}`,

    [theme.breakpoints.between('sm', 'lg')]: {
      display: 'none',
    },

    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  };
});

const VerticalWrapper = styled(Stack, { shouldForwardProp })<StackProps>(({ theme }) => {
  return {
    margin: '0 !important',
    padding: theme.spacing(1, 1.5),
    borderTop: `1px solid ${theme.palette.stroke.soft}`,

    [theme.breakpoints.between('sm', 'lg')]: {
      display: 'flex',
    },

    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  };
});

const NavFooterItem = ({ text, to, icon }: NavItemProps) => {
  return (
    <Link to={to} target='_blank' rel='noopener noreferrer'>
      <MapleButton
        size='small'
        startIcon={<MapleIcon icon={icon} height='15px' width='15px' color='icon.soft' />}
        variant='textLink'
      >
        {text}
      </MapleButton>
    </Link>
  );
};

const SidebarFooter = () => {
  return (
    <>
      <HorizontalWrapper direction='row' alignItems='center' justifyContent='space-between' spacing={1}>
        {supportNavItems.map((link, index) => (
          <NavFooterItem key={index} text={link.text} to={link.to} icon={link.icon} />
        ))}
      </HorizontalWrapper>

      <VerticalWrapper direction='column' alignItems='flex-start' spacing={1}>
        {supportNavItems.map((link, index) => (
          <NavFooterItem key={index} text={link.text} to={link.to} icon={link.icon} />
        ))}
      </VerticalWrapper>
    </>
  );
};

export default SidebarFooter;
