import { MouseEvent, Dispatch, SetStateAction } from 'react';
import { Stack, styled, SyrupPaletteColor } from '@mui/material';
import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';
import ToggleButtonGroup, { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';
import MapleTypography from 'Components/ds/MapleTypography';
import MapleTooltip, { MapleTooltipProps } from './MapleTooltip';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme, orientation }) => ({
  width: '100%',
  display: 'flex',
  gap: orientation === 'horizontal' ? theme.spacing(1) : theme.spacing(2.5),
  flexDirection: orientation === 'horizontal' ? 'row' : 'column',
  '.MuiToggleButtonGroup-grouped': {
    flex: '1 0 0px', // Makes all buttons take equal space
    minWidth: 0, // Allows buttons to shrink below content size if needed
    borderRadius: theme.shape.md,
  },
  span: {
    flex: '1 0 0px', // Makes all tooltip buttons take equal space
    minWidth: 0, // Allows buttons to shrink below content size if needed
  },
}));

const StyledToggleButton = styled(ToggleButton)<ToggleButtonProps>(({ theme }) => {
  const hoverStyles = {
    backgroundColor: theme.palette.primary.contrastText,
    color: (theme.palette.primary as SyrupPaletteColor).text,
    borderColor: theme.palette.primary.main,
  };

  return {
    textTransform: 'none',
    border: '1px solid transparent',
    borderRadius: theme.shape.sm,
    backgroundColor: theme.palette.primary.contrastText,
    color: (theme.palette.primary as SyrupPaletteColor).text,
    padding: `${theme.spacing(1.5)} ${theme.spacing(1.5)} ${theme.spacing(1)}`,
    width: '100%',

    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.white,

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.white,
      },
    },

    '&:hover': { ...hoverStyles },

    '&.Mui-disabled': {
      backgroundColor: theme.palette.background.weak,
      color: theme.palette.text.disabled,
      borderColor: 'transparent',
    },
  };
});

type MapleToggleGroupPropsItem = {
  label: string;
  value: string;
  id: string;
  disabled?: boolean;
  tooltip?: MapleTooltipProps;
};

interface MapleToggleGroupProps extends ToggleButtonGroupProps {
  items: MapleToggleGroupPropsItem[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: Dispatch<SetStateAction<any>>;
  analyticsEvent?: (value: string) => void;
}

const MapleToggleGroup = ({
  items,
  setValue,
  value,
  color = 'primary',
  orientation = 'horizontal',
  analyticsEvent,
  ...rest
}: MapleToggleGroupProps) => {
  const handleChange = (_event: MouseEvent<HTMLElement>, value: string) => {
    if (!value) return;
    if (analyticsEvent) analyticsEvent(value);
    setValue(value);
  };

  return (
    <StyledToggleButtonGroup value={value} exclusive onChange={handleChange} orientation={orientation} {...rest}>
      {items.map(({ label, value, id, disabled, tooltip }) =>
        tooltip ? (
          <MapleTooltip key={value} title={tooltip.title} content={tooltip.content}>
            <StyledToggleButton value={id} color={color} disabled={disabled || value === id}>
              <Stack direction='column' spacing={0.5} width='100%'>
                <Stack direction='row' justifyContent='space-between'>
                  <MapleTypography variant='label' size='xSmall' align='left' className='label'>
                    {label}
                  </MapleTypography>
                  {tooltip.children}
                </Stack>

                <MapleTypography variant='h7' align='left' className='value'>
                  {value}
                </MapleTypography>
              </Stack>
            </StyledToggleButton>
          </MapleTooltip>
        ) : (
          <StyledToggleButton key={value} value={id} color={color} disabled={disabled || value === id}>
            <Stack direction='column' spacing={0.5} width='100%'>
              <MapleTypography variant='label' size='xSmall' align='left' className='label'>
                {label}
              </MapleTypography>

              <MapleTypography variant='h7' align='left' className='value'>
                {value}
              </MapleTypography>
            </Stack>
          </StyledToggleButton>
        ),
      )}
    </StyledToggleButtonGroup>
  );
};

export default MapleToggleGroup;
