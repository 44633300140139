import { TEN, ZERO } from 'Constants/numbers';
import { ISSUANCE_RATE_DECIMALS, OT_ISSUANCE_RATE_DECIMALS } from 'Constants/tokens';
import { PoolFragment, PoolWithdrawalFragment } from 'Graphql/schema';
import { getNowTimestamp } from 'Utils/timeAndDates';
import { isDust } from 'Utils/numbers';

export const getCurrentInterest = (
  domainEnd: string,
  domainStart: string,
  issuanceRate: string,
  issuanceRateDecimals: number,
): bigint => {
  const start = +domainStart;
  const end = +domainEnd;
  const now = getNowTimestamp();
  if (now < start) return ZERO; // edge case, now should be after domainStart
  const last = now > end ? end : now; // loanManager does not accrue interest after domainEnd, although late interest continues accruing
  const secondsSinceStart = BigInt(last - start);
  const issuance = BigInt(issuanceRate); // interest accrual in assets per second
  const decimalsAdjustment = TEN ** BigInt(issuanceRateDecimals); // precision of issuanceRate
  const currentInterest = (issuance * secondsSinceStart) / decimalsAdjustment;

  return currentInterest;
};

export const getAssetsUnderManagement = (
  accountedInterest: string,
  domainEnd: string,
  domainStart: string,
  issuanceRate: string,
  principalOut: string,
  issuanceRateDecimals: number,
): bigint => {
  const accounted = BigInt(accountedInterest);
  const current = getCurrentInterest(domainEnd, domainStart, issuanceRate, issuanceRateDecimals);
  const outstandingInterest = accounted + current;
  const principal = BigInt(principalOut);
  const assetsUnderManagement = principal + outstandingInterest; // total value of all loans

  return assetsUnderManagement;
};

export const getTotalAssets = (pool: PoolWithdrawalFragment): bigint => {
  const cash = BigInt(pool.assets);

  let combinedAssets = ZERO;

  if (pool?.openTermLoanManager) {
    const { accountedInterest, domainEnd, domainStart, issuanceRate, principalOut } = pool.openTermLoanManager;
    const openTermAssets = getAssetsUnderManagement(
      accountedInterest,
      domainEnd,
      domainStart,
      issuanceRate,
      principalOut,
      OT_ISSUANCE_RATE_DECIMALS,
    );
    combinedAssets = combinedAssets + openTermAssets;
  }

  if (pool.loanManager) {
    const { accountedInterest, domainEnd, domainStart, issuanceRate, principalOut } = pool.loanManager;
    const fixedTermAssets = getAssetsUnderManagement(
      accountedInterest,
      domainEnd,
      domainStart,
      issuanceRate,
      principalOut,
      ISSUANCE_RATE_DECIMALS,
    );
    combinedAssets = combinedAssets + fixedTermAssets;
  }

  const totalAssets = cash + combinedAssets;

  return totalAssets;
};

export const convertToExitShares = (assets: bigint, pool: PoolWithdrawalFragment): bigint => {
  const totalAssets = getTotalAssets(pool);
  const impaired = BigInt(pool.unrealizedLosses);
  const totalAssetsLessImpaired = totalAssets - impaired;
  const totalShares = BigInt(pool.shares);
  const convertedShares = totalAssetsLessImpaired === ZERO ? ZERO : (assets * totalShares) / totalAssetsLessImpaired;

  return convertedShares;
};

// Convert shares to assets; excludes impairment from total assets
export const convertToExitAssets = (shares: bigint, pool: PoolWithdrawalFragment): bigint => {
  const totalAssets = getTotalAssets(pool);
  const impaired = BigInt(pool.unrealizedLosses);
  const totalAssetsLessImpaired = totalAssets - impaired;
  const totalShares = BigInt(pool.shares);
  const convertedAssets = totalShares === ZERO ? ZERO : (shares * totalAssetsLessImpaired) / totalShares;

  return convertedAssets;
};

export const getWithdrawalShares = ({
  amount,
  pool,
  availableBalance,
  availableShares,
}: {
  amount: bigint;
  pool: PoolWithdrawalFragment;
  availableBalance: bigint;
  availableShares: bigint;
}) => {
  const isDustLeftover = isDust(availableBalance - amount);

  // make sure all shares are requested in case user inputs (almost) full balance
  const shares = isDustLeftover ? availableShares : convertToExitShares(amount, pool);

  return shares;
};

export const getSyrupApy = (syrupPool: PoolFragment): string => {
  if (!syrupPool) return '0';

  const { apy, monthlyApy } = syrupPool;

  if (monthlyApy && monthlyApy !== '0') return monthlyApy; // preferably render 30-day APY

  return apy ?? '0'; // fallback to spot APY
};
