import { useContext, useMemo, MouseEvent } from 'react';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

// Assets
import { ReactComponent as SyrupLogoCropped } from 'Assets/syrup-logo-cropped.svg';

// Components
import MapleButton from 'Components/ds/MapleButton';
import MapleIcon from 'Components/ds/MapleIcon';
import MapleTypography from 'Components/ds/MapleTypography';
import Banner from 'Components/Banner';
import MaplePopover from 'Components/MaplePopover';
import SidebarNavItem from 'Components/Navigation/SidebarNavItem';

// Context
import { ClientContext } from 'Context/Client';
import { ClaimContext } from 'Context/Claim';
import { DataContext } from 'Context/Data';

// Hooks
import useBreakpoint from 'Hooks/useBreakpoint';

// Styles
const mediaWrapper = {
  width: '100%',
  height: '100%',
  backgroundColor: theme => ({ xs: theme.palette.background.weak, md: theme.palette.background.white }),
  svg: { transform: { xs: 'scale(1.5) translateY(-8px)', md: 'scale(1)' } },
};
const footerWrapper = {
  width: '100%',
  borderRadius: theme => theme.shape.lg,
  py: { xs: 1.5, md: 0.75 },
  px: 1.5,
  border: theme => `1px solid ${theme.palette.stroke.soft}`,
};

const ClaimBanner = ({ onClose }: { onClose: () => void }) => {
  const navigate = useNavigate();
  const { openConnectModal } = useConnectModal();
  const { connected } = useContext(ClientContext);
  const { claimState, nextClaimStartDate, currentClaimPeriod, setIsModalOpen } = useContext(ClaimContext);
  const { stakingApy } = useContext(DataContext);
  const { isDesktopDevice, breakPoint } = useBreakpoint();
  const { palette } = useTheme();

  const text = useMemo((): Record<'heading' | 'copy' | 'cta', string> => {
    if (!connected) {
      return {
        heading: 'Claim SYRUP',
        copy: 'Connect your wallet to see if you are eligible',
        cta: 'Connect Wallet',
      };
    }

    if (claimState === 'ineligible') {
      return {
        heading: 'Nothing to Claim',
        copy: 'Come back next Claim Period to see if you qualify',
        cta: nextClaimStartDate,
      };
    }

    if (claimState === 'claim-available') {
      return {
        heading: 'Claim SYRUP',
        copy: 'Claim your SYRUP and start earning',
        cta: 'Claim SYRUP',
      };
    }

    if (claimState === 'claim-unavailable') {
      return {
        heading: 'Upcoming Claim Period',
        copy: 'Come back next Claim Period to claim more Drips',
        cta: nextClaimStartDate,
      };
    }

    throw new Error('Invalid claim state');
  }, [claimState, nextClaimStartDate, connected]);

  const ineligibleOrUnavailableClaim = useMemo(() => {
    return connected && ['ineligible', 'claim-unavailable'].includes(claimState);
  }, [claimState, connected]);

  const handleCTA = ({ target }: MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    if (!connected) {
      openConnectModal && openConnectModal();
      return;
    }

    if (claimState === 'claim-available') {
      setIsModalOpen(true);
      navigate('/drips');
    }

    if ((target as HTMLElement).getAttribute('data-close-banner') === 'true') {
      handleClose();
    }
  };

  const handleClose = () => {
    localStorage.setItem('claimBanner', currentClaimPeriod);
    onClose();
  };

  if (breakPoint === 'lg')
    return (
      <MaplePopover copy={['Claim your SYRUP and', `Stake to earn ${stakingApy.formatted}% APY`]}>
        <SidebarNavItem icon='syrup' isActive={true} onClick={handleCTA} />
      </MaplePopover>
    );

  return (
    <Banner
      media={
        <Stack alignItems='center' justifyContent='flex-end' sx={mediaWrapper}>
          <SyrupLogoCropped />
        </Stack>
      }
      tag={{
        label: `Claim Period ${ineligibleOrUnavailableClaim ? +currentClaimPeriod + 1 : currentClaimPeriod}`,
        color: 'orange',
      }}
      handleClose={handleClose}
      title={text.heading}
      description={text.copy}
      footer={
        ineligibleOrUnavailableClaim ? (
          <Stack direction='row' justifyContent='center' spacing={1} sx={footerWrapper}>
            <MapleIcon icon='calendarFill' size={16} color='text.sub' />
            <MapleTypography textAlign='center' variant='label' size={isDesktopDevice ? 'xSmall' : 'small'}>
              {text.cta}
            </MapleTypography>
          </Stack>
        ) : (
          <MapleButton
            fullWidth
            variant='contained'
            color='secondary'
            size={isDesktopDevice ? 'xSmall' : 'medium'}
            onClick={handleCTA}
          >
            {text.cta}
          </MapleButton>
        )
      }
      backgroundColor={claimState === 'claim-available' ? palette.primary.contrastText : undefined}
    />
  );
};

export default ClaimBanner;
