// No commitment = 1 drip per day, 10 as integer
// 90d = 1.5x drips per day, 15 as integer
// 180d = 3x drips per day, 30 as integer
const DRIPS_MAPPING: { [days: number]: number } = {
  0: 10,
  90: 15,
  180: 30,
};

export const DRIPS_PER_DAY = (days: number) => {
  return days in DRIPS_MAPPING ? DRIPS_MAPPING[days] : 10;
};
