import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

// Components
import MapleTypography from 'Components/ds/MapleTypography';

// Icon
import { ReactComponent as IconProgress } from 'Assets/icon-modal-progress.svg';

export interface StepInProgressProps {
  heading: string;
  copy: string;
}

const StepInProgress = ({ heading, copy }: StepInProgressProps) => {
  return (
    <Stack spacing={6} alignItems='center'>
      <Stack spacing={1} textAlign='center'>
        <MapleTypography variant='h5' px={3} py={0.5}>
          {heading}
        </MapleTypography>
        <MapleTypography variant='label' size='small' color='text.soft'>
          {copy}
        </MapleTypography>
      </Stack>
      <Stack direction='column' alignItems='center' sx={{ position: 'relative' }}>
        <IconProgress height={152} width={152} />
        <CircularProgress color='primary' size={78} sx={{ position: 'absolute', top: '24px', left: '38px' }} />
      </Stack>
    </Stack>
  );
};

export default StepInProgress;
