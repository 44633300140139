import { styled } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';

// Theme
import { zIndex } from 'Theme/designSystem';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  zIndex: zIndex.highest,
  '& .MuiPaper-root': {
    border: `1px solid ${theme.palette.stroke.soft}`,
    borderRadius: theme.shape.md,
    marginTop: theme.spacing(1),
    minWidth: 100,
    boxShadow: theme.syrupShadows.sm,
    '& .MuiMenu-list': {
      padding: theme.spacing(1),
    },
    '& .MuiMenuItem-root': {
      padding: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.palette.background.weak,
      },
      '&:active': {
        backgroundColor: theme.palette.background.weak,
      },
    },
  },
}));

export default StyledMenu;
