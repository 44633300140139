import { useContext, useMemo } from 'react';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Components
import StepCommitInput from 'Components/Steps/StepCommitInput';
import MapleIcon from 'Components/ds/MapleIcon';
import MapleTypography from 'Components/ds/MapleTypography';
import TransactionModal, { TransactionModalProps } from 'Components/TransactionModal';
import StepRecommitSelect from 'Components/Steps/StepRecommitSelect';

// Context
import { DataContext } from 'Context/Data';
import { CommitContext } from 'Context/Commit/Commit';
import { ClientContext } from 'Context/Client';
import { PortfolioContext } from 'Context/Portfolio';

// Graphql
import { useRecommitMutation } from 'Graphql/schema';

// Utils
import { convertToCommitDays } from 'Utils/points';

const RecommitModal = () => {
  const navigate = useNavigate();
  const { account, web3Client } = useContext(ClientContext);
  const { lendingAssets } = useContext(DataContext);
  const { refetch } = useContext(PortfolioContext);
  const {
    isDoingTx,
    setIsDoingTx,
    formValues,
    setFormValues,
    step,
    setStep,
    reinitialize,
    isModalOpen,
    expectedDrips,
    expectedAPY,
    maturityDate,
    commitments,
    selectCommitment,
  } = useContext(CommitContext);

  const [recommit] = useRecommitMutation();

  const handleResetFlow = async () => {
    navigate('/portfolio');
    reinitialize();
  };

  const header = useMemo(() => {
    if (isDoingTx) return undefined;
    if (step === 'selectCommitment') {
      return (
        <Stack direction='row' spacing={1} alignItems='center' justifyContent='flex-start' width='100%'>
          <MapleIcon icon='coinsFill' />
          <MapleTypography variant='h6' sx={{ transform: 'translateY(3px)' }}>
            Recommit balance
          </MapleTypography>
        </Stack>
      );
    }

    if (step === 'form') {
      return (
        <Stack direction='row' spacing={1} alignItems='center' justifyContent='flex-start' width='100%'>
          <MapleIcon icon={formValues.commitment.asset} size='32px' />
          <Stack direction='column'>
            <MapleTypography variant='h6'>
              {formValues.commitment.exitAssets.formatted} {formValues.commitment.asset.toUpperCase()} committed
            </MapleTypography>
            <MapleTypography variant='paragraph' size='small'>
              Recommit now to keep earning more Drips
            </MapleTypography>
          </Stack>
        </Stack>
      );
    }
  }, [step, isDoingTx, formValues.commitment]);

  const inProgressProps = useMemo((): TransactionModalProps['inProgressProps'] => {
    return {
      recommit: {
        heading: 'Recommitting',
        copy: 'Follow the prompts in your wallet to recommit your balance',
      },
    };
  }, []);

  const successProps = useMemo((): TransactionModalProps['successProps'] => {
    return {
      heading: ['Balance recommitted', `for ${formValues.commitLength} months`],
      type: 'withFooterButtons',
      footer: {
        ctaText: 'Go to my Portfolio',
        onCTAClick: handleResetFlow,
      },
      amount: formValues.commitment.amount.formatted,
      asset: formValues.commitment.asset,
      onClose: handleResetFlow,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.commitLength, formValues.commitment, formValues.commitment.amount]);

  const failProps = useMemo((): TransactionModalProps['failProps'] => {
    return {
      handleClick: handleResetFlow,
    };
  }, []);

  const handleSelect = () => {
    setStep('form');
  };

  const handleForm = async () => {
    if (!web3Client || !account) return;

    try {
      setStep('recommit');
      setIsDoingTx(true);

      const {
        commitment: { amount, asset, id },
        commitLength,
      } = formValues;

      const message = `Recommit ${amount.formatted} ${asset.toUpperCase()} for ${commitLength} months`;
      const signature = await web3Client.signMessage({ account, message });

      const { data } = await recommit({
        variables: {
          address: account,
          commitmentId: id,
          days: convertToCommitDays(commitLength),
        },
        context: {
          headers: {
            'x-auth-message': message,
            'x-auth-signature': `${signature}:${account}`,
          },
        },
      });

      if (data?.recommit?.success) setStep('success');
      else throw new Error('Failed to recommit balance');
    } catch (error) {
      console.error('👻 RecommitModal:handleForm :::', { error });
      setStep('fail');
    } finally {
      setIsDoingTx(false);
      refetch();
    }
  };

  return (
    <TransactionModal
      step={step}
      closeModal={handleResetFlow}
      isModalOpen={isModalOpen}
      performingTx={isDoingTx}
      inProgressProps={inProgressProps}
      successProps={successProps}
      failProps={failProps}
      size='small'
      header={header}
      headerAlign='left'
    >
      {step === 'selectCommitment' && (
        <StepRecommitSelect
          formValues={formValues}
          commitments={commitments}
          selectCommitment={selectCommitment}
          goToNextStep={handleSelect}
        />
      )}
      {step === 'form' && (
        <StepCommitInput
          key={formValues.type}
          showAmount={false}
          formValues={formValues}
          setFormValues={setFormValues}
          assets={lendingAssets}
          dripsEstimate={expectedDrips}
          yieldEstimate={expectedAPY}
          maturityDate={maturityDate}
          onSubmit={handleForm}
          ctaCopy='Recommit for'
        />
      )}
    </TransactionModal>
  );
};

export default RecommitModal;
