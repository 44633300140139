import { useMemo, Fragment, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

// Components
import { MapleButtonProps } from 'Components/ds/MapleButton';
import Banner from 'Components/Banner';
import MaplePopover from 'Components/MaplePopover';
import SidebarNavItem from 'Components/Navigation/SidebarNavItem';

// Types
import { PortfolioUserState } from 'Context/Portfolio/Portfolio';

// Hooks
import useBreakpoint from 'Hooks/useBreakpoint';
import RecommitCTA from 'Pages/Portfolio/RecommitCTA';
import CommitCTA from 'Pages/Portfolio/CommitCTA';

const CommitBanner = ({ onClose, userState }: { onClose: () => void; userState: PortfolioUserState }) => {
  const { isDesktopDevice, breakPoint } = useBreakpoint();
  const { palette } = useTheme();

  const handleClose = () => {
    localStorage.setItem('commitBanner', userState);
    onClose();
  };

  const buttons = useMemo((): JSX.Element[] | null => {
    const commonProps = {
      size: isDesktopDevice ? 'xSmall' : ('medium' as MapleButtonProps['size']),
      fullWidth: true,
    };

    if (userState === 'uncomitted-and-committed') {
      return [<RecommitCTA {...commonProps} />, <CommitCTA {...commonProps} color='secondary' />];
    }

    if (userState === 'committed-full') {
      return [<RecommitCTA {...commonProps} />];
    }

    if (userState === 'uncommitted-full') {
      return [<CommitCTA {...commonProps} />];
    }

    return null;
  }, [userState, isDesktopDevice]);

  if (breakPoint === 'lg')
    return (
      <MaplePopover copy={['The Drips program is winding down.', 'Commit now to earn the most SYRUP!']}>
        <SidebarNavItem
          icon='commit'
          isActive={true}
          onClick={buttons ? buttons[buttons.length - 1].props.onClick : undefined}
          sx={{ '.badge__inner': { backgroundColor: palette.primary.light } }}
        />
      </MaplePopover>
    );

  return (
    <Banner
      media={
        <video width='100%' loop autoPlay muted playsInline>
          <source src='https://downloads.eth.maple.finance/videos/Banner_S.mp4' type='video/mp4' />
        </video>
      }
      handleClose={handleClose}
      title='Maximize your Drips'
      description='Recommit your balance to earn the most Drips.'
      footer={
        buttons && (
          <Stack direction='row' spacing={0.5}>
            {buttons.map((item, index) => (
              <Fragment key={`commit-banner-btn-${index}`}>{item}</Fragment>
            ))}
          </Stack>
        )
      }
    />
  );
};

export default CommitBanner;
