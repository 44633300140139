import Stack from '@mui/material/Stack';

// Components
import MapleButton from 'Components/ds/MapleButton';
import PositionRadioGroup from 'Components/PositionRadioGroup';

// Types
import { FormattedCommitment } from 'Context/Portfolio/utils';
import { CommitFormValues } from 'Context/Commit/Commit';

interface StepRecommitSelectProps {
  formValues: CommitFormValues;
  commitments: FormattedCommitment[];
  selectCommitment: (id: string) => void;
  goToNextStep: () => void;
}

const StepRecommitSelect = ({ formValues, commitments, selectCommitment, goToNextStep }: StepRecommitSelectProps) => {
  return (
    <Stack direction='column' spacing={3}>
      <PositionRadioGroup
        options={commitments.map(({ exitAssets, length, unlockDate, asset, id }) => ({
          title: `$${exitAssets.formatted}`,
          copy: `${length} | Available ${unlockDate}`,
          asset,
          type: 'basic',
          id,
        }))}
        selectedValue={formValues.commitment?.id}
        onChange={selectCommitment}
      />

      <MapleButton variant='contained' onClick={goToNextStep}>
        Continue
      </MapleButton>
    </Stack>
  );
};

export default StepRecommitSelect;
