import { ReactNode } from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// Components
import MapleTypography from 'Components/ds/MapleTypography';
import MapleIcon, { Icons } from 'Components/ds/MapleIcon';
import MapleIconBadge from 'Components/ds/MapleIconBadge';
import MapleTooltip, { MapleTooltipProps } from 'Components/ds/MapleTooltip';
import MapleButton from 'Components/ds/MapleButton';

// Hooks
import useBreakpoint from 'Hooks/useBreakpoint';

// Utils
import { ValueInterface } from 'Utils/valueInterface';
import { PoolAssets } from 'Context/Portfolio/Portfolio';

const styles = {
  width: '100%',
  padding: theme => `${theme.spacing(2, 0)} !important`,
  '&:nth-last-of-type(1)': {
    padding: 0,
  },
};

interface CommonProps {
  title: string;
  asset: PoolAssets | 'usdc-pendle';
  copy?: string;
  badge?: Icons;
  id: string;
}

interface BasicItem extends CommonProps {
  type: 'basic';
}

interface TextItem extends CommonProps {
  type: 'text';
  value: string;
  tooltip?: MapleTooltipProps;
}

interface CTAItem extends CommonProps {
  type: 'cta';
  items: Array<ReactNode> | [];
}

interface LinkItem extends CommonProps {
  type: 'link';
  url: string;
  value: string;
  amount: ValueInterface;
  tooltip?: MapleTooltipProps;
}

export type PortfolioRowItemProps = BasicItem | TextItem | CTAItem | LinkItem;

const PortfolioRowItem = ({ ...props }: PortfolioRowItemProps) => {
  const { copy, title, badge, type, asset, id } = props;

  const { isDesktopDevice } = useBreakpoint();

  const iconDimension = isDesktopDevice ? '40px' : '30px';

  const textProps = {
    variant: 'paragraph' as const,
    size: 'medium' as const,
    color: 'text.strong',
  };
  let cellValue: ReactNode;

  switch (type) {
    case 'text': {
      const { tooltip, value } = props;

      cellValue = (
        <Stack>
          <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={0.5}>
            <MapleTypography {...textProps} textAlign='right'>
              {value}
            </MapleTypography>
            {tooltip && <MapleTooltip {...tooltip} />}
          </Stack>
          <MapleTypography
            {...textProps}
            sx={{ display: { xs: 'none', md: 'block' }, opacity: 0, cursor: 'none', userSelect: 'none' }}
          >
            {value}
            {/* ghost row */}
          </MapleTypography>
        </Stack>
      );
      break;
    }
    case 'cta':
      cellValue = (
        <Stack direction='row' spacing={1} sx={{ width: { xs: '100%', md: 'auto' } }}>
          {props.items.map((item, index) => (
            <Box key={index} sx={{ width: { xs: '100%', md: 'auto' } }}>
              {item}
            </Box>
          ))}
        </Stack>
      );
      break;
    case 'link': {
      const { amount, url, value, tooltip } = props;
      cellValue = (
        <Stack sx={{ a: { textDecoration: 'none' } }}>
          <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={0.5}>
            <MapleTypography {...textProps}>${amount.formatted}</MapleTypography>
            {tooltip && <MapleTooltip {...tooltip} />}
          </Stack>

          <Link href={url} target='_blank' rel='noopener'>
            <MapleButton variant='textLink' endIcon={<MapleIcon icon='arrowRightUp' />} size='small'>
              {value}
            </MapleButton>
          </Link>
        </Stack>
      );
      break;
    }
  }

  return (
    <Box sx={{ ...styles }} key={`portfolio-row-item-${id}`}>
      <Stack direction='row' spacing={2} justifyContent='space-between'>
        <Stack direction='row' justifyContent='flex-start' spacing={2}>
          <MapleIconBadge icon={asset} width={iconDimension} height={iconDimension} badge={badge} />
          <Stack direction='column' textAlign='left' justifyContent={copy ? 'space-between' : 'center'}>
            <MapleTypography variant='paragraph' size='medium'>
              {title}
            </MapleTypography>
            {copy && (
              <MapleTypography variant='paragraph' size='small' color='text.soft'>
                {copy}
              </MapleTypography>
            )}
          </Stack>
        </Stack>
        <Stack direction='row' alignItems='center' justifyContent='flex-end'>
          {cellValue}
        </Stack>
      </Stack>
    </Box>
  );
};

export default PortfolioRowItem;
