import { useState, useMemo, useContext, useEffect } from 'react';

// Components
import ClaimBanner from 'Components/ClaimBanner';
import CommitBanner from 'Components/CommitBanner';
import MapleCarousel from 'Components/ds/MapleCarousel';

// Constants
import { isRecommitEnabled } from 'Constants';

// Context
import { ClientContext } from 'Context/Client';
import { ClaimContext } from 'Context/Claim';
import { PortfolioContext } from 'Context/Portfolio';

// Hooks
import useBreakpoint from 'Hooks/useBreakpoint';
import useIsGnosisSafe from 'Hooks/useIsGnosisSafe';

export const BANNERS = {
  CLAIM: {
    id: 'claim',
    storageKey: 'claimBanner',
    priority: 1,
  },
  COMMIT: {
    id: 'commit',
    storageKey: 'commitBanner',
    priority: 2,
  },
} as const;

const DesktopBannersCarousel = () => {
  const { connected } = useContext(ClientContext);
  const { claimState, currentClaimPeriod, loading } = useContext(ClaimContext);
  const { userState, loading: portfolioLoading } = useContext(PortfolioContext);
  const { breakPoint } = useBreakpoint();
  const gnosisConnectionType = useIsGnosisSafe();

  const [open, setOpen] = useState<null | boolean>(null);
  const [showClaimBanner, setShowClaimBanner] = useState(false);
  const [showCommitBanner, setShowCommitBanner] = useState(false);

  useEffect(() => {
    if (!connected) return setShowClaimBanner(true);
    if (currentClaimPeriod === localStorage.getItem(BANNERS.CLAIM.storageKey)) return setShowClaimBanner(false);

    return setShowClaimBanner(['claim-available', 'claim-unavailable'].includes(claimState));
  }, [loading, connected, claimState, currentClaimPeriod]);

  useEffect(() => {
    if (
      !connected ||
      localStorage.getItem(BANNERS.COMMIT.storageKey) ||
      portfolioLoading ||
      gnosisConnectionType !== 'none'
    )
      return setShowCommitBanner(false);

    return setShowCommitBanner(userState !== 'no-balance');
  }, [connected, userState, portfolioLoading, gnosisConnectionType]);

  const banners = useMemo(() => {
    if (open === false || portfolioLoading) return [];

    const availableBanners: { [key: string]: React.ReactNode } = {};

    if (showClaimBanner) {
      availableBanners['CLAIM'] = <ClaimBanner onClose={() => setShowClaimBanner(false)} />;
    }

    if (isRecommitEnabled && showCommitBanner) {
      availableBanners['COMMIT'] = <CommitBanner userState={userState} onClose={() => setShowCommitBanner(false)} />;
    }

    setOpen(true);

    const sortedBanners = Object.keys(availableBanners).sort((a, b) => BANNERS[a].priority - BANNERS[b].priority);
    return sortedBanners.map(key => availableBanners[key]);
  }, [open, showClaimBanner, showCommitBanner, userState, portfolioLoading]);

  return (
    <MapleCarousel
      height={breakPoint === 'xxl' ? 235 : 50}
      name='sidebar-banners'
      itemsSpacing={breakPoint === 'xxl' ? 0 : 2.25}
      margins={breakPoint === 'xxl' ? 0.5 : 2.4}
      navigation='top'
      items={banners}
    />
  );
};

export default DesktopBannersCarousel;
