import { ReactNode, useState } from 'react';
import { styled } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

// Assets
import { RiInformationLine, RiCloseLine } from '@remixicon/react';

// Hooks
import useBreakpoint from 'Hooks/useBreakpoint';

// Components
import MapleTypography from 'Components/ds/MapleTypography';

export interface MapleTooltipProps {
  title?: ReactNode | string;
  content?: ReactNode | string;
  children?: ReactNode;
  placement?: TooltipProps['placement'];
}

const StyledIconButton = styled(IconButton)(({ theme, size }) => ({
  padding: 0,
  color: theme.palette.text.soft,
  backgroundColor: 'transparent',
  ...(!size && { width: '16px', height: '16px' }),
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:focus': {
    backgroundColor: 'transparent',
  },
}));

const PopperProps = {
  sx: {
    '.MuiTooltip-tooltip': {
      padding: theme => theme.spacing(1.5),
      backgroundColor: theme => theme.palette.background.white,
      opacity: '1',
      boxShadow: theme => theme.syrupShadows.md,
      borderRadius: theme => theme.shape.md,
      border: theme => `1px solid ${theme.palette.stroke.soft}`,
    },
  },
};

interface MapleTooltipContentProps {
  title?: ReactNode | string;
  content?: ReactNode | string;
  onClose?: () => void;
}

const MapleTooltipContent = ({ title, content, onClose }: MapleTooltipContentProps) => {
  return (
    <Stack spacing={2.25}>
      {(title || onClose) && (
        <Stack direction='row' justifyContent='space-between'>
          <MapleTypography variant='label' size='small' color='text.strong'>
            {title}
          </MapleTypography>
          {onClose && (
            <StyledIconButton size='small' onClick={onClose}>
              <RiCloseLine size={20} />
            </StyledIconButton>
          )}
        </Stack>
      )}
      {typeof content === 'string' ? (
        <MapleTypography variant='paragraph' size='small' color='text.soft'>
          {content}
        </MapleTypography>
      ) : (
        content
      )}
    </Stack>
  );
};

const MapleTooltip = ({ title, content, children, ...props }: MapleTooltipProps) => {
  const { isDesktopDevice } = useBreakpoint();
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  if (isDesktopDevice)
    return (
      <Tooltip
        title={<MapleTooltipContent title={title} content={content} />}
        PopperProps={PopperProps}
        // leaveDelay={99999999} // helpful for styling tooltips
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -6],
                },
              },
            ],
          },
        }}
        {...props}
      >
        {children ? (
          <span>{children}</span>
        ) : (
          <StyledIconButton>
            <RiInformationLine size={16} />
          </StyledIconButton>
        )}
      </Tooltip>
    );

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          title={<MapleTooltipContent title={title} content={content} onClose={handleTooltipClose} />}
          PopperProps={PopperProps}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -6],
                  },
                },
              ],
            },
          }}
          {...props}
        >
          {children ? (
            <>{children}</>
          ) : (
            <StyledIconButton onClick={handleTooltipOpen}>
              <RiInformationLine size={16} />
            </StyledIconButton>
          )}
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default MapleTooltip;
