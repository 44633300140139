import { Dispatch, SetStateAction, useMemo } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// Components
import MapleToggleGroup from 'Components/ds/MapleToggleGroup';
import MapleInfoCell from 'Components/ds/MapleInfoCell';
import MapleIcon from 'Components/ds/MapleIcon';
import MapleButton from 'Components/ds/MapleButton';
import MapleInputAsset from 'Components/ds/MapleInputAsset';

// Hooks
import { useAnalytics } from 'Hooks/useAnalytics';

// Types
import { AssetFragment } from 'Graphql/schema';
import { SyrupPositions } from 'Context/Portfolio/Portfolio';
import { AssetProps } from 'Components/ds/MapleAssetSelect';
import { CommitFormValues } from 'Context/Commit/Commit';

// Utils
import { ValueInterface } from 'Utils/valueInterface';
import { isZeroish } from 'Utils/numbers';
import { commitmentLengthMap } from 'Utils/points';
import { defaultCommitFormValues } from 'Utils/defaultValues';

/// Styles
const styles = {
  warningIcon: {
    backgroundColor: theme => theme.palette.warning.contrastText,
    width: '17px',
    height: '17px',
    borderRadius: theme => theme.shape.round,
  },
};

interface StepCommitInputProps {
  formValues: CommitFormValues;
  setFormValues: Dispatch<SetStateAction<CommitFormValues>>;
  showAmount: boolean;
  assets: AssetFragment[];
  positions?: SyrupPositions;
  dripsEstimate: ValueInterface;
  yieldEstimate: ValueInterface;
  maturityDate: string;
  onSubmit: () => void;
  ctaCopy: string;
}

const StepCommitInput = ({
  assets,
  positions,
  formValues,
  setFormValues,
  showAmount,
  dripsEstimate,
  yieldEstimate,
  maturityDate,
  onSubmit,
  ctaCopy,
}: StepCommitInputProps) => {
  const { analytics } = useAnalytics();

  const assetInputItems = useMemo((): AssetProps[] => {
    if (!positions) return [];

    return assets.reduce((acc, { id, decimals, symbol }) => {
      if (Object.prototype.hasOwnProperty.call(positions, symbol.toLowerCase())) {
        const { availableBalance } = positions[symbol.toLowerCase()];
        const noBalance = isZeroish(availableBalance.bigNumber);

        acc.push({
          id,
          decimals,
          symbol,
          balance: { ...availableBalance },
          disabled: noBalance,
        });
      }

      return acc;
    }, [] as AssetProps[]);
  }, [assets, positions]);

  const handleSetPosition = (symbol: string) => {
    if (!positions) return;

    const position = positions[symbol.toLowerCase()];

    setFormValues({ ...defaultCommitFormValues, position });
  };

  const maxValue = useMemo(() => {
    return formValues.position.availableBalance;
  }, [formValues.position.availableBalance]);

  const trackMaxCommit = () => {
    analytics?.track('Portfolio - Commit - Click MAX', { amount: maxValue.formatted });
  };

  const insufficientBalance = useMemo(
    () => formValues.amount.bigNumber > formValues.position.availableBalance.bigNumber,
    [formValues.amount, formValues.position.availableBalance],
  );

  const isValid = formValues.type === 'commit' ? !insufficientBalance && !isZeroish(formValues.amount.bigNumber) : true;

  const is3MonthsCommitDisabled = useMemo(
    () => formValues.commitment.length === commitmentLengthMap[180],
    [formValues.commitment.length],
  );

  const selectedAsset = assets.find(asset => asset.symbol.toLowerCase() === formValues.position.asset) || assets[0];

  return (
    <Stack spacing={3} direction='column'>
      <Stack spacing={1.5} direction='column'>
        {showAmount ? (
          <MapleInputAsset
            name='commitAmount'
            placeholder='0'
            setValue={value => setFormValues({ ...formValues, amount: value })}
            value={formValues.amount}
            maxValue={maxValue}
            assets={assetInputItems}
            selectedAsset={selectedAsset}
            setAsset={handleSetPosition}
            analyticsEvent={trackMaxCommit}
            inputHelperText='Available'
            assetHelperText='Available'
            error={insufficientBalance}
          />
        ) : null}

        <MapleToggleGroup
          items={[
            {
              label: 'Commit 3 months',
              value: '1.5x Drips',
              id: '3',
              disabled: is3MonthsCommitDisabled,
              tooltip: is3MonthsCommitDisabled
                ? {
                    content: 'Commitment period cannot be shortened; it can only be recommitted.',
                    children: (
                      <Stack alignItems='center' justifyContent='center' sx={styles.warningIcon}>
                        <MapleIcon icon='alertFill' size='10px' color='warning.main' />
                      </Stack>
                    ),
                  }
                : undefined,
            },
            { label: 'Commit 6 months', value: '3x Drips', id: '6' },
          ]}
          setValue={value => setFormValues({ ...formValues, commitLength: value })}
          value={formValues.commitLength}
        />

        <Box>
          <MapleInfoCell
            type='icon'
            title={`Total expected Drips in ${formValues.commitLength} months`}
            value={dripsEstimate.formatted}
            icon={<MapleIcon icon='syrupBottleFull' />}
            location='top'
          />

          <MapleInfoCell type='basic' title='Estimated APY' value={`${yieldEstimate.formatted}%`} location='middle' />

          <MapleInfoCell
            type='icon'
            title='Maturity date'
            value={maturityDate}
            icon={<MapleIcon icon='calendarFill' size='18px' color='primary.main' />}
            location='bottom'
          />
        </Box>
      </Stack>
      <MapleButton onClick={onSubmit} disabled={!isValid}>
        {ctaCopy} {formValues.commitLength} months
      </MapleButton>
    </Stack>
  );
};
export default StepCommitInput;
