import { styled } from '@mui/material';

import { Stack } from '@mui/material';

// Components
import MapleButton from 'Components/ds/MapleButton';
import MapleIcon, { MapleIconProps } from 'Components/ds/MapleIcon';
import MapleTypography from 'Components/ds/MapleTypography';
import MapleSwitch from 'Components/ds/MapleSwitch';

const StyledGradientStack = styled(Stack)(({ theme }) => ({
  background: theme.palette.gradient.linearC,
  boxShadow: theme.syrupShadows.xxxl,
  borderRadius: theme.shape.md,
  padding: theme.spacing(1.5),

  '&.inactive': {
    background: theme.palette.background.weak,

    '.MuiTypography-root': {
      color: theme.palette.text.strong,
    },

    svg: {
      fill: theme.palette.text.strong,
    }
  },
}));

const StyledMapleButton = styled(MapleButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.white,
  color: theme.palette.text.primary,
  transition: 'none',

  '&:hover': {
    backgroundColor: theme.palette.background.weak,
  },

  '.inactive &': {
    backgroundColor: theme.palette.background.strong,
    color: theme.palette.text.white,
  },
}));

interface CommonProps {
  label: string;
  value: string;
  valueIcon?: MapleIconProps['icon'];
  ctaText: string;
  handleClick: () => void;
}

interface NormalProps extends CommonProps {
  type: 'normal';
}

interface ToggleProps extends CommonProps {
  type: 'toggle';
  checked: boolean;
  handleToggle: () => void;
}

export type ModalGradientCTAProps = NormalProps | ToggleProps;

const ModalGradientCTA = (props: ModalGradientCTAProps) => {
  const { label, value, ctaText, handleClick, type } = props;
  return (
    <StyledGradientStack
      spacing={2}
      alignItems='flex-start'
      className={type === 'toggle' && !props.checked ? 'inactive' : ''}
    >
      <Stack direction='column' sx={{ width: '100%' }}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
          <MapleTypography variant='h7' color='text.white'>
            {label}
          </MapleTypography>

          {type === 'toggle' && <MapleSwitch checked={props.checked} onChange={props.handleToggle} sx={{ p: 0 }} />}
        </Stack>
        <Stack direction='row' alignItems='center' spacing={1}>
          {props.valueIcon && <MapleIcon icon={props.valueIcon} height='16px' width='16px' color='text.white' />}
          <MapleTypography variant='paragraph' size='small' color='text.white' textAlign='left'>
            {value}
          </MapleTypography>
        </Stack>
      </Stack>
      <StyledMapleButton variant='contained' size='large' fullWidth onClick={handleClick}>
        {ctaText}
      </StyledMapleButton>
    </StyledGradientStack>
  );
};

export default ModalGradientCTA;
