import { RadioGroup, FormControlLabel, Stack } from '@mui/material';
import PortfolioRowItem, { PortfolioRowItemProps } from 'Pages/Portfolio/PortfolioRowItem';
import { ChangeEvent } from 'react';
import MapleRadioButton from 'Components/ds/MapleRadioButton';

const styles = {
  container: {
    backgroundColor: 'background.weak',
    borderRadius: theme => theme.shape.sm,
    paddingLeft: theme => theme.spacing(2),
    cursor: 'pointer',
  },
};

interface PositionRadioGroupProps {
  options: PortfolioRowItemProps[];
  selectedValue: string;
  onChange: (value: string) => void;
}

const PositionRadioGroup = ({ options, selectedValue, onChange }: PositionRadioGroupProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <RadioGroup value={selectedValue} onChange={handleChange}>
      <Stack direction='column' spacing={1.5}>
        {options.map((option, index) => (
          <Stack
            key={`position-radio-group-${index}`}
            direction='row'
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={styles.container}
            onClick={() => onChange(option.id)}
          >
            <PortfolioRowItem {...option} />
            <FormControlLabel
              key={`position-radio-group-${option.id}`}
              value={option.id}
              control={<MapleRadioButton />}
              label=''
              sx={{ marginLeft: 'auto' }}
            />
          </Stack>
        ))}
      </Stack>
    </RadioGroup>
  );
};

export default PositionRadioGroup;
