import { formatUnits } from 'viem';

import { PERCENTAGE_UI_DECIMALS } from 'Constants/tokens';

export const getTargetYieldAverage = (positions: { balance: string; assetDecimals: number; targetYield: number }[]) => {
  let totalAvailableBalance = 0;
  let totalContribution = 0;

  positions.forEach(position => {
    const balance = +formatUnits(BigInt(position.balance), position.assetDecimals);
    // Use balance or 1 as the weight - this ensures positions with 0 balance still contribute
    const weight = balance || 1;
    const contribution = weight * position.targetYield;
    totalAvailableBalance += weight;
    totalContribution += contribution;
  });

  return +(totalContribution / totalAvailableBalance).toFixed(PERCENTAGE_UI_DECIMALS);
};
