import { ReactNode } from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

// Components
import MapleDialog, { MapleDialogProps } from 'Components/ds/MapleDialog';
import StepInProgress, { StepInProgressProps } from 'Components/Steps/StepInProgress';
import StepSuccess, { StepSuccessProps } from 'Components/Steps/StepSuccess';
import StepFail, { StepFailProps } from 'Components/Steps/StepFail';

export interface TransactionModalProps {
  step: string; // 'success' | 'fail' | 'form' | transactions
  closeModal: () => void;
  performingTx?: boolean;
  inProgressProps?: Record<string, StepInProgressProps>;
  successProps?: StepSuccessProps;
  failProps?: StepFailProps;
  children?: ReactNode;
  size?: MapleDialogProps['size'];
  isModalOpen: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  headerAlign?: MapleDialogProps['headerAlign'];
}

const TransactionModal = ({
  step,
  closeModal,
  performingTx,
  inProgressProps,
  successProps,
  failProps,
  children,
  isModalOpen,
  size = 'small',
  header,
  footer,
  headerAlign = 'default',
}: TransactionModalProps) => {
  // // Prevent user from navigating away from the page
  // useEffect(() => {
  //   const handleBeforeUnload = (event: any) => {
  //     event.preventDefault();
  //     event.returnValue = '';
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  // // Prevent user from using the back button or swiping left on Mac OS X
  // useEffect(() => {
  //   const handlePopState = () => {
  //     history.pushState(null, '', `/#${pathname}`);
  //   };

  //   window.addEventListener('popstate', handlePopState);

  //   return () => {
  //     window.removeEventListener('popstate', handlePopState);
  //   };
  // }, [pathname]);

  const shouldRenderChildren = !performingTx && step !== 'success' && step !== 'fail';

  return (
    <MapleDialog
      open={isModalOpen}
      handleClose={closeModal}
      size={size}
      withCloseIcon={!['success', 'fail'].includes(step) && !performingTx}
      header={header}
      headerAlign={headerAlign}
      footer={footer}
      disableClose={performingTx}
    >
      {performingTx && inProgressProps && <StepInProgress {...inProgressProps[step]} />}

      {step === 'success' && successProps && <StepSuccess {...successProps} />}

      {step === 'fail' && failProps && <StepFail {...failProps} />}

      {shouldRenderChildren && (
        <>
          {children || (
            <Stack justifyContent='center' alignItems='center'>
              <CircularProgress />
            </Stack>
          )}
        </>
      )}
    </MapleDialog>
  );
};

export default TransactionModal;
