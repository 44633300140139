import { Box } from '@mui/material';
import MapleIcon, { Icons, MapleIconProps } from 'Components/ds/MapleIcon';

interface MapleIconBadgeProps extends MapleIconProps {
  badge?: Icons;
}

const badgeCircleStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  right: 0,
  paddingTop: '1px',
  transform: 'translate(16%, -25%)',
  width: '22px',
  height: '22px',
  borderWidth: '1.5px',
  borderRadius: theme => theme.shape.round,
  borderStyle: 'solid',
  borderColor: theme => theme.palette.icon.white,
  backgroundColor: theme => theme.palette.static.black,
};

const MapleIconBadge = ({ icon, badge, width, height, ...rest }: MapleIconBadgeProps) => {
  return (
    <Box position='relative' width={width} height={height}>
      <MapleIcon icon={icon} width={width} height={height} {...rest} />
      {badge && (
        <Box sx={{ ...badgeCircleStyles }}>
          <MapleIcon icon={badge} size='100%' height='12px' width='12px' color='static.white' />
        </Box>
      )}
    </Box>
  );
};

export default MapleIconBadge;
