import {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  useGetPortfolioDataQuery,
  GetPortfolioDataQuery,
  PoolWithdrawalFragment,
  BasicTransactionFragment,
} from 'Graphql/schema';

// Context
import { ClientContext } from 'Context/Client';

// Constants
import { POLLING_INTERVAL, PROJECT } from 'Constants';
import { defaultPositionData, defaultWithdrawalData } from 'Utils/defaultValues';

// Utils
import { EMPTY_PERCENTAGE, ValueInterface, ZERO_DASH_USDC, ZERO_USDC } from 'Utils/valueInterface';
import { CountdownResult } from 'Utils/timeAndDates';
import {
  FormattedCommitment,
  getFormattedPositionData,
  getFormattedStats,
  getWithdrawalData,
} from 'Context/Portfolio/utils';
import { isZeroish } from 'Utils/numbers';

export type PortfolioUserState =
  | 'none'
  | 'no-balance'
  | 'committed-full'
  | 'uncommitted-full'
  | 'uncomitted-and-committed';

export type WithdrawalStates =
  | 'NotConnected'
  | 'NoPosition'
  | 'Inactive'
  | 'Pending'
  | 'Processed'
  | 'ProcessedPartial'
  | 'Cancelled';

export type PoolAssets = 'usdc' | 'usdt';

export interface WithdrawalData {
  withdrawalState: WithdrawalStates;
  requestedAmount: ValueInterface; // assets
  remainingAmount: ValueInterface; // assets
  requestedWithdrawalTx?: BasicTransactionFragment;
}

type SyrupWithdrawals = Record<PoolAssets, WithdrawalData>;

export interface PositionData {
  // Assets
  asset: PoolAssets;
  totalBalance: ValueInterface;
  availableBalance: ValueInterface;
  availableShares: ValueInterface;
  lockedShares: ValueInterface;
  interestEarned: ValueInterface;

  // Points
  commitments: FormattedCommitment[];
  firstCommitmentExpiry: CountdownResult | null;
  dripsEarned: string;
}

export type SyrupPositions = Record<PoolAssets, PositionData>;

export interface StatsData {
  totalDrips: string;
  totalBalance: ValueInterface;
  totalAvailableBalance: ValueInterface;
  interestEarned: Record<'total' | 'usdt' | 'usdc', ValueInterface>;
  targetYieldAverage: number;
}

type PortfolioData = StatsData & {
  positions: SyrupPositions;
  withdrawals: SyrupWithdrawals;

  pools: PoolWithdrawalFragment[];
  txes: BasicTransactionFragment[];

  userState: PortfolioUserState;

  loading: boolean;
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  refetch: () => void;
  startPolling: (value: number) => void;
  stopPolling: () => void;
};

const defaultWithdrawalsData: SyrupWithdrawals = {
  usdc: { ...defaultWithdrawalData },
  usdt: { ...defaultWithdrawalData },
};

const defaultPositionsData: SyrupPositions = {
  usdc: { ...defaultPositionData },
  usdt: { ...defaultPositionData },
};

const defaultStatsData: StatsData = {
  totalDrips: '0',
  interestEarned: {
    total: { ...ZERO_USDC },
    usdt: { ...ZERO_USDC },
    usdc: { ...ZERO_USDC },
  },
  totalBalance: { ...ZERO_DASH_USDC },
  totalAvailableBalance: { ...ZERO_USDC },
  targetYieldAverage: 0,
};

const defaultPortfolioData: PortfolioData = {
  ...defaultStatsData,

  positions: { ...defaultPositionsData },
  withdrawals: { ...defaultWithdrawalsData },

  pools: [],
  txes: [],

  userState: 'none',

  loading: true,
  activeTab: 0,
  setActiveTab: () => {},
  refetch: () => {},
  startPolling: () => {},
  stopPolling: () => {},
};

export const PortfolioContext = createContext<PortfolioData>(defaultPortfolioData);

export interface PortfolioProps {
  children: ReactNode;
}

export const PortfolioProvider = ({ children }: PortfolioProps): ReactElement => {
  const { account } = useContext(ClientContext);
  const [positionsData, setPositionsData] = useState<SyrupPositions>({ ...defaultPositionsData });
  const [withdrawalsData, setWithdrawalsData] = useState<SyrupWithdrawals>({ ...defaultWithdrawalsData });
  const [statsData, setStatsData] = useState<StatsData>({ ...defaultStatsData });
  const [userState, setUserState] = useState<PortfolioUserState>('none');
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const {
    data,
    loading: queryLoading,
    refetch,
    startPolling,
    stopPolling,
  } = useGetPortfolioDataQuery({
    variables: {
      account: account?.toLowerCase() || '',
      accountId: account?.toLowerCase() || '',
    },
    skip: !account,
    pollInterval: PROJECT === 'localhost' ? 0 : POLLING_INTERVAL,
  });

  useEffect(() => {
    const processData = async () => {
      if (!queryLoading && data) {
        try {
          await init(data);
        } finally {
          setIsLoading(false);
        }
      } else {
        return setIsLoading(false);
      }
    };
    setIsLoading(true);
    processData();
  }, [queryLoading, data]);

  useEffect(() => {
    // Only reset state when account changes. Otherwise no account will be stuck in isLoading: true
    if (!account) return;
    resetState();
  }, [account]);

  const resetState = () => {
    setIsLoading(true);
    setPositionsData({ ...defaultPositionsData });
    setWithdrawalsData({ ...defaultWithdrawalsData });
    setStatsData({ ...defaultStatsData });
    setUserState('none');
  };

  const init = async (data: GetPortfolioDataQuery): Promise<void> => {
    if (!account) return;

    const newPositions: SyrupPositions = { ...defaultPositionsData };
    const newWithdrawals: SyrupWithdrawals = { ...defaultWithdrawalsData };

    for (const pool of data.poolV2S) {
      const asset = pool.asset.symbol.toLowerCase();
      const position = data.poolV2Positions.find(p => p.pool.asset.symbol.toLowerCase() === asset);

      newPositions[asset] = getFormattedPositionData(pool, position);
      newWithdrawals[asset] = getWithdrawalData(account, pool, data?.txes, position);
    }

    const { interestEarned, totalDrips, totalBalance, totalAvailableBalance, targetYieldAverage } = getFormattedStats(
      data.poolV2S,
      data.accountById,
      data.poolV2Positions,
    );

    setPositionsData(newPositions);
    setWithdrawalsData(newWithdrawals);
    setStatsData({ interestEarned, totalDrips, totalBalance, totalAvailableBalance, targetYieldAverage });

    const userState = getUserState(totalBalance.bigNumber, totalAvailableBalance.bigNumber);

    setUserState(userState);
  };

  const getUserState = (totalBalance: bigint, totalAvailableBalance: bigint): PortfolioUserState => {
    const hasLendingBalance = !isZeroish(totalBalance);
    const hasAvailableBalance = !isZeroish(totalAvailableBalance);
    if (!hasLendingBalance) return 'no-balance';
    if (hasLendingBalance && !hasAvailableBalance) return 'committed-full';
    if (totalAvailableBalance === totalBalance) return 'uncommitted-full';
    if (hasLendingBalance && hasAvailableBalance) return 'uncomitted-and-committed';

    throw new Error('Unexpected user state');
  };

  return (
    <PortfolioContext.Provider
      value={{
        positions: { ...positionsData },
        withdrawals: { ...withdrawalsData },

        pools: data?.poolV2S || [],
        txes: data?.txes || [],

        ...statsData,

        userState,

        activeTab,
        setActiveTab,
        refetch,
        startPolling,
        stopPolling,

        loading: isLoading,
      }}
    >
      {children}
    </PortfolioContext.Provider>
  );
};
