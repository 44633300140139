import { useRef, useMemo } from 'react';
import { useAccount } from 'wagmi';
import { parseUnits } from 'viem';

import { styled } from '@mui/material/styles';
import Stack, { StackProps } from '@mui/material/Stack';
import Button, { ButtonProps } from '@mui/material/Button';
import { Box, BoxProps, FilledInput } from '@mui/material';

// Components
import MapleTypography from 'Components/ds/MapleTypography';
import MapleAssetSelect, { AssetProps } from 'Components/ds/MapleAssetSelect';
import NumericFormatCustom from 'Components/ds/NumericFormat';

// Interfaces
import { STABLE_COINS_UI_DECIMALS } from 'Constants/tokens';
import { AssetFragment } from 'Graphql/schema';

// Utils
import { buildValueInterfaceFromBigNumberValues, defaultValueInterface, ValueInterface } from 'Utils/valueInterface';
import { defaultAssetData } from 'Utils/defaultValues';

const styles = {
  usdValue: {
    color: theme => theme.palette.text.soft,
    whiteSpace: 'normal',
  },
  balance: {
    color: theme => theme.palette.text.soft,
  },
  error: {
    color: theme => theme.palette.error.main,
  },
};

const defaultAssetPropsData = {
  ...defaultAssetData,
  apy: { ...defaultValueInterface },
  balance: { ...defaultValueInterface },
};

const InputAssetHelper = styled(Stack)<StackProps>(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  position: 'absolute',
  top: theme.spacing(7.5),
  width: '100%',
  span: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const MaxButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.text.strong,
  minWidth: theme.spacing(3.5),
  padding: 0,
  ...theme.typography.paragraph,
  ...theme.typography.paragraph.xSmall,
}));

const InputWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'relative',

  '.MuiInputBase-root': {
    background: theme.palette.background.weak,
    borderColor: theme.palette.background.weak,
    borderWidth: '1px',
    borderStyle: 'solid',

    '&:hover, &:focus-within': {
      background: theme.palette.background.weak,
      borderColor: theme.palette.background.weak,
    },

    '&:focused': {
      background: 'transparent',
    },

    '&::before': {
      display: 'none',
    },
    '&::after': {
      display: 'none',
    },
    '&::placeholder': {
      color: theme.palette.text.soft,
      opacity: 1,
    },
  },
  '.MuiInputBase-input': {
    padding: theme.spacing(1, 0, 0, 0),
    fontSize: '20px',
    fontWeight: 500,
    letterSpacing: '0.1px',
    color: theme.palette.text.strong,
    background: 'transparent',

    '&:hover, &.Mui-focused': {
      background: 'transparent',
    },
  },

  '.Mui-disabled': {
    background: theme.palette.background.weak,
    borderColor: theme.palette.background.weak,
  },

  '.input-wrapper__inner': {
    position: 'relative',
    backgroundColor: theme.palette.background.weak,
    padding: theme.spacing(1.5, 16, 1.5, 1.5),
    borderColor: 'transparent',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: theme.shape.sm,
    minHeight: '98px',

    '&:hover': {
      borderColor: 'transparent',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: theme.shape.sm,
      backgroundColor: theme.palette.background.weak,
    },

    '&:focus-within': {
      borderColor: theme.palette.static.black,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: theme.shape.sm,
      backgroundColor: theme.palette.background.weak,
    },
  },

  '.input-wrapper__assets': {
    position: 'absolute',
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
  },
}));

interface MapleInputProps {
  inputHelperText?: string;
  assetHelperText?: string;
  name: string;
  placeholder?: string;
  assets: AssetProps[];
  maxValue: ValueInterface;
  disabled?: boolean;
  setAsset: (id: string) => void;
  selectedAsset: AssetFragment;
  setValue: (value: ValueInterface) => void;
  value: ValueInterface;
  error: boolean;

  analyticsEvent?: () => void;
}

export default function MapleInput({
  assets,
  setValue,
  value,
  maxValue,
  selectedAsset,
  setAsset,
  disabled,
  analyticsEvent,
  inputHelperText,
  assetHelperText,
  error,
}: MapleInputProps) {
  const { isConnected } = useAccount();

  const inputRef = useRef<HTMLInputElement>(null);
  const isCtaChange = useRef(false);

  const asset = useMemo(
    () =>
      assets.find(asset => asset.symbol.toLowerCase() === selectedAsset.symbol.toLowerCase()) || {
        ...defaultAssetPropsData,
      },
    [assets, selectedAsset],
  );

  const handleChange = value => {
    // Avoid perform any change if Max button was clicked
    if (isCtaChange.current) {
      isCtaChange.current = false;
      return;
    }

    const newVal = parseUnits(value, asset.decimals);

    const newValFormatted = buildValueInterfaceFromBigNumberValues(newVal, asset.decimals, STABLE_COINS_UI_DECIMALS);

    if (newValFormatted.formatted === maxValue.formatted) setValue(maxValue);
    else setValue(newValFormatted);
  };

  const handleClickMax = () => {
    isCtaChange.current = true;
    setValue(maxValue);

    inputRef?.current?.focus();

    if (analyticsEvent) analyticsEvent();
  };

  return (
    <InputWrapper>
      <div className='input-wrapper__inner'>
        <FilledInput
          placeholder='0'
          inputComponent={NumericFormatCustom as never}
          inputProps={{ inputMode: 'numeric', 'data-decimals': STABLE_COINS_UI_DECIMALS, 'data-value': value }}
          onChange={value => handleChange(value)}
          disabled={disabled}
        />
      </div>
      <div className='input-wrapper__assets'>
        <MapleAssetSelect
          selectedAsset={asset}
          setSelectedAsset={asset => setAsset(asset.symbol)}
          assets={assets}
          assetHelperText={assetHelperText}
        />
      </div>
      {isConnected && (
        <InputAssetHelper direction='row' justifyContent='flex-end' alignItems='end' spacing={1}>
          <Stack direction='row' spacing={1}>
            {error ? (
              <MapleTypography variant='paragraph' size='small' sx={styles.error} component='span'>
                Exceeds Balance
              </MapleTypography>
            ) : (
              <MapleTypography variant='paragraph' size='small' sx={styles.balance} component='span'>
                {inputHelperText || 'Wallet'}: {asset.balance.formatted}
              </MapleTypography>
            )}
            <MaxButton onClick={handleClickMax} size='small' variant='text'>
              MAX
            </MaxButton>
          </Stack>
        </InputAssetHelper>
      )}
    </InputWrapper>
  );
}
