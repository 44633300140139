import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

// Components
import MapleButton from 'Components/ds/MapleButton';
import MapleTypography from 'Components/ds/MapleTypography';

// Icon
import { ReactComponent as IconError } from 'Assets/icon-modal-error.svg';

export interface StepFailProps {
  copy?: string | string[];
  handleClick: () => void;
}

const DEFAULT_FAILURE_MESSAGE = 'This Transaction unfortunately failed. Please try again.';

const StepFail = ({ handleClick, copy = DEFAULT_FAILURE_MESSAGE }: StepFailProps) => {
  return (
    <Stack direction='column' spacing={4} justifyContent='space-between'>
      <Stack spacing={2} paddingX={4} alignItems='center'>
        <Stack direction='column' alignItems='center'>
          <IconError height={108} width={108} />
        </Stack>
        <MapleTypography variant='h5' color='text.strong'>
          Transaction Failed!
        </MapleTypography>

        <Box paddingX={4} textAlign='center'>
          {Array.isArray(copy) ? (
            copy.map((text, index) => (
              <MapleTypography key={index} variant='label' size='small' color='text.soft'>
                {text}
              </MapleTypography>
            ))
          ) : (
            <MapleTypography variant='label' size='small' color='text.soft'>
              {copy}
            </MapleTypography>
          )}
        </Box>
      </Stack>

      <Stack>
        <MapleButton fullWidth variant='contained' color='primary' onClick={handleClick}>
          Try again
        </MapleButton>
      </Stack>
    </Stack>
  );
};

export default StepFail;
