import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { isClaimEnabled as defaultIsClaimEnabled } from 'Constants';

const useFeatureFlags = () => {
  const location = useLocation();

  // Parse query parameters
  const queryParams = new URLSearchParams(location.search);

  // Extract convert parameter
  const convertParam = queryParams.get('claim');

  // Track if isConvertEnabled was ever true
  const hasBeenEnabled = useRef(false);

  // Initialize the state, but only set to true once
  const [isClaimEnabled, setIsClaimEnabled] = useState(() => {
    const enabled = convertParam !== null || defaultIsClaimEnabled;
    if (enabled) {
      hasBeenEnabled.current = true;
    }
    return enabled;
  });

  useEffect(() => {
    // If isConvertEnabled is already true, don't change it
    if (!isClaimEnabled && convertParam !== null) {
      setIsClaimEnabled(true);
      hasBeenEnabled.current = true;
    }
  }, [convertParam, isClaimEnabled]);

  useEffect(() => {
    // Ensure that once isConvertEnabled is true, it never goes back to false
    if (hasBeenEnabled.current) {
      setIsClaimEnabled(true);
    }
  }, [isClaimEnabled]);

  return {
    isClaimEnabled,
  };
};

export default useFeatureFlags;
