import { useMemo } from 'react';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

// Components
import MapleTypography from 'Components/ds/MapleTypography';
import MapleIconButton from 'Components/ds/MapleIconButton';
import MapleIcon from 'Components/ds/MapleIcon';

// Icons
import SyrupLogo from 'Assets/syrup-logo-footer.svg';

// Hooks
import useBreakpoint from 'Hooks/useBreakpoint';
import { useAnalytics } from 'Hooks/useAnalytics';
import useFeatureFlags from 'Hooks/useFeatureFlags';

const socialLinks: { title: string; icon: 'telegram' | 'xFill'; url: string }[] = [
  { title: 'Telegram', icon: 'telegram', url: 'https://t.me/+6UdN_NkuRroyNGRl' },
  { title: 'X/Twitter', icon: 'xFill', url: 'https://x.com/syrupfi' },
];

const wrapperStyles = {
  width: '100%',
  position: 'relative',
  backgroundColor: 'background.weak',
  pb: { xs: 18, md: 0 },
  backgroundImage: `url(${SyrupLogo})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: { xs: 'top left', md: 'calc(100% - 20%) top' },
  transform: { xs: 'translateY(0)', md: 'translateY(10px)' },
};

const linksWrapper = {
  transform: theme => ({ xs: 'none', md: `translateY(-${theme.spacing(5.25)})` }),
};

const linkStyles = {
  fontSize: theme => ({
    xs: theme.typography.paragraph.small.fontSize,
    md: theme.typography.paragraph.medium.fontSize,
  }),
  lineHeight: theme => ({
    xs: theme.typography.paragraph.small.fontSize,
    md: theme.typography.paragraph.medium.lineHeight,
  }),
  letterSpacing: theme => ({
    xs: theme.typography.paragraph.small.fontSize,
    md: theme.typography.paragraph.medium.letterSpacing,
  }),
  fontWeight: 400,
  '&:hover': {
    color: theme => theme.palette.text.strong,
  },
};

const copyRightStyles = {
  paddingRight: theme => ({ xs: 0, md: theme.spacing(8), lg: 0 }),
};

const Footer = () => {
  const { isClaimEnabled } = useFeatureFlags();
  const { analytics, initialized } = useAnalytics();
  const { isDesktopDevice } = useBreakpoint();
  const { pathname } = useLocation();
  const props: { typographySize: 'medium' | 'small'; maxWidth: 'sm' | 'md' } = isDesktopDevice
    ? { typographySize: 'medium', maxWidth: 'sm' }
    : { typographySize: 'small', maxWidth: 'md' };

  const navLinks = useMemo(() => {
    const isConvertOrStakePath = pathname.startsWith('/convert') || pathname.startsWith('/stake');
    const tosUrl = isConvertOrStakePath
      ? 'https://syrup.gitbook.io/syrup/legal/interface-terms-of-use-syrup.fi-convert-and-stake'
      : 'https://syrup.gitbook.io/syrup/legal/interface-terms-of-use';

    return [
      { title: 'Interface Terms of Use', url: tosUrl },
      { title: 'Documentation', url: 'https://syrup.gitbook.io/syrup' },
      { title: 'FAQ', url: 'https://syrup.gitbook.io/syrup/troubleshooting-and-support/faq' },
    ];
  }, [pathname]);

  const fireMixpanelEvent = (_: React.MouseEvent, text: string) => {
    if (initialized) analytics?.page(text);
  };

  return (
    <Stack
      sx={{ ...wrapperStyles, pl: isClaimEnabled ? { xs: '0px', md: '80px', lg: '216px' } : '0px' }}
      spacing={1.5}
      alignItems='center'
    >
      <Container maxWidth='lg'>
        <Stack direction={{ xs: 'row-reverse', md: 'row' }} justifyContent='space-between' alignItems='center'>
          <Stack direction='row' spacing={1.5}>
            {socialLinks.map(({ url, icon, title }) => (
              <ReactRouterLink key={icon} to={url} target='_blank' rel='noopener noreferrer'>
                <MapleIconButton size='small' color='secondary' onClick={e => fireMixpanelEvent(e, title)}>
                  <MapleIcon icon={icon} size='16' color='primary.main' />
                </MapleIconButton>
              </ReactRouterLink>
            ))}
          </Stack>

          <MapleTypography variant='label' size={props.typographySize} color='text.soft' sx={copyRightStyles}>
            {`©Syrup ${new Date().getFullYear()}`}
          </MapleTypography>
        </Stack>
      </Container>
      <Container maxWidth={props.maxWidth} sx={linksWrapper}>
        <Stack direction='row' spacing={3} justifyContent={{ xs: 'space-between', md: 'center' }}>
          {navLinks.map(({ title, url }) => (
            <Link
              key={title}
              href={url}
              underline='none'
              color={'text.soft'}
              sx={linkStyles}
              target='_blank'
              rel='noopener noreferrer'
            >
              <MapleTypography
                variant='label'
                size={props.typographySize}
                color='text.soft'
                onClick={e => fireMixpanelEvent(e, title)}
              >
                {title}
              </MapleTypography>
            </Link>
          ))}
        </Stack>
      </Container>
    </Stack>
  );
};

export default Footer;
