import { ReactNode, createContext, useMemo } from 'react';

// Hooks
import useIsMarketingPage from 'Hooks/useIsMarketingPage';
import useConvertPageLocation from 'Hooks/useConvertPageLocation';

// GraphQL
import { useIsRestrictedQuery } from 'Graphql/schema';

export interface RestrictedContextType {
  isRestricted: boolean;
}
export const RestrictedContext = createContext<RestrictedContextType>({
  isRestricted: false,
});

type Props = {
  children: ReactNode;
};

export const RestrictedProvider = ({ children }: Props) => {
  const isMarketingPage = useIsMarketingPage();
  const isConvertPage = useConvertPageLocation();

  const { error: isRestrictedError } = useIsRestrictedQuery({
    skip: isMarketingPage || isConvertPage,
  });
  const isGeoBlocked = useMemo(() => {
    if (isRestrictedError?.graphQLErrors?.[0]?.extensions?.code === 'RESTRICTED') return true;
    return false;
  }, [isRestrictedError]);

  return <RestrictedContext.Provider value={{ isRestricted: isGeoBlocked }}>{children}</RestrictedContext.Provider>;
};
