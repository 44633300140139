import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';

// Components
import MapleIcon from 'Components/ds/MapleIcon';
import MapleTooltip, { MapleTooltipProps } from 'Components/ds/MapleTooltip';
import MapleTypography, { MapleTypographyProps } from 'Components/ds/MapleTypography';
import MapleButton from 'Components/ds/MapleButton';

const StyledCell = styled(Box)<MapleInfoCellProps>(({ theme, location }) => {
  let borderRadius = '0';

  const radius = theme.shape.sm;

  if (location === 'top') {
    borderRadius = `${radius} ${radius} 0 0`;
  }

  if (location === 'middle') {
    borderRadius = '0';
  }

  if (location === 'bottom') {
    borderRadius = `0 0 ${radius} ${radius}`;
  }

  if (location === 'single') {
    borderRadius = radius;
  }

  return {
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.background.weak,
    borderRadius,
  };
});

interface TextProps {
  align?: MapleTypographyProps['align'];
  variant?: MapleTypographyProps['variant'];
  size?: MapleTypographyProps['size'];
  color?: string;
}

interface CommonProps {
  title: string;
  location?: 'top' | 'middle' | 'bottom' | 'single';
  textProps?: TextProps;
}

interface ValueProps {
  value: string;
}

interface BasicCell extends CommonProps, ValueProps {
  type: 'basic';
}

interface IconCell extends CommonProps, ValueProps {
  type: 'icon';
  icon: ReactNode;
}

interface TooltipCell extends CommonProps, ValueProps {
  type: 'tooltip';
  tooltip: Pick<MapleTooltipProps, 'title' | 'content'>;
}

interface LinkCell extends CommonProps {
  type: 'link';
  url: string;
}

export type MapleInfoCellProps = BasicCell | IconCell | TooltipCell | LinkCell;

const defaultTextProps: TextProps = {
  align: 'right',
  variant: 'paragraph',
  size: 'small',
  color: 'text.strong',
};

const MapleInfoCell = (props: MapleInfoCellProps) => {
  const { title, type } = props;
  const { textProps: customTextProps, ...restProps } = props;
  const textProps = { ...defaultTextProps, ...customTextProps };

  let cellValue: ReactNode;

  switch (type) {
    case 'basic':
      cellValue = <MapleTypography {...textProps}>{props.value}</MapleTypography>;
      break;
    case 'icon':
      cellValue = (
        <Stack direction='row' alignItems='center' spacing={1}>
          <MapleTypography {...textProps}>{props.value}</MapleTypography>
          {props.icon}
        </Stack>
      );
      break;
    case 'tooltip':
      cellValue = (
        <Stack direction='row' alignItems='center' spacing={1}>
          <MapleTypography {...textProps}>{props.value}</MapleTypography>
          <MapleTooltip {...props.tooltip} />
        </Stack>
      );
      break;
    case 'link':
      cellValue = (
        <Link href={props.url} target='_blank' rel='noopener'>
          <MapleButton variant='textLink' size='small' endIcon={<MapleIcon icon='arrowRightUp' />}>
            View
          </MapleButton>
        </Link>
      );
      break;
  }

  return (
    <StyledCell {...restProps}>
      <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
        <MapleTypography {...textProps} color='text.soft'>
          {title}
        </MapleTypography>
        <Stack direction='row' alignItems='center'>
          {cellValue}
        </Stack>
      </Stack>
    </StyledCell>
  );
};

export default MapleInfoCell;
